import React from "react";
import { useRootQuery } from "~/context/RootQuery/RootQuery";
import Nav from "~/components/Nav/Nav";
import "prismjs/themes/prism-tomorrow.css";
import Header from "~/components/Header/Header";
import { MDXProvider } from "@mdx-js/react";
import CodeBlock from "~/components/CustomMDX/CodeBlock/CodeBlock";
import CzrLink from "~/components/CustomMDX/CzrLink/CzrLink";
import CButton from "~/components/CButton/CButton"
import cx from "~/utils/cx";
import sh from "shelljs";

// Styles
import "@ionic/react/css/core.css";

// state
import { themeSelector, versionSelector, setVersion } from "~/state/globalSlice";
import { useSelector, useDispatch } from "react-redux";

const components = {
  pre: CodeBlock,
  a: CzrLink,
  CButton
};

export const Index = ({ location: { pathname }, children }) => {
  const theme = useSelector(themeSelector);
  const versionState = useSelector(versionSelector);
  const { version } = useRootQuery();
  const sortedVersions = version.allAirtableVersion.nodes.sort((a,b) => {
    return +a.data.Version.replaceAll('.', '') < +b.data.Version.replaceAll('.', '') ? 1 : -1
  });
  const [latestVersion] = [...sortedVersions];
  const dispatch = useDispatch();
  if (!versionState) dispatch(setVersion(latestVersion.data.Version));
  return (
    <div
      id="caesars-ui-app"
      className={cx(
        { [`${theme} ${theme.includes('dark') ? 'darkMode' : ''}`]: true },
        "flex flex-row min-h-screen bg-bg-default"
      )}
    >
      <Nav theme={theme} />
      <main className="relative w-full p-10 bg-bg-default text-fg-default mdx">
        <div className="relative z-10">
          <Header />
          <MDXProvider components={components}>
            <div className="w-full max-w-[1280px]">{children}</div>
          </MDXProvider>
        </div>
        <div
          className={cx(
            {
              "bg-czr-pattern": pathname === "/",
              "opacity-20": theme === "czr-light",
              "opacity-40": theme === "czr-dark",
            },
            "absolute inset-0 bg-repeat pointer-events-none z-0 h-1/2 before:absolute before:inset-0 before:bg-gradient-to-b before:from-[rgba(0,0,0,0)] before:to-bg-default"
          )}
        ></div>
      </main>
    </div>
  );
};

export default Index;
