import React from "react";
import { CardProps } from "../types";
import { CardSection } from "./CardSection";

export const CardSubHeader = ({
  children,
  ...rest
}: Partial<CardProps>) => <CardSection x="cui__card-subheader cui-px-md" {...rest}>
    {children}
  </CardSection>;
  

CardSubHeader.displayName  = "CardSubHeader";