
    export default {
        "fg-default": "var(--fg-default)",
	"fg-moderate": "var(--fg-moderate)",
	"fg-subtle": "var(--fg-subtle)",
	"fg-minimal": "var(--fg-minimal)",
	"fg-inverse": "var(--fg-inverse)",
	"fg-primary": "var(--fg-primary)",
	"fg-primary-strong": "var(--fg-primary-strong)",
	"fg-primary-minimal": "var(--fg-primary-minimal)",
	"fg-global-black": "var(--fg-global-black)",
	"fg-global-white": "var(--fg-global-white)",
	"fg-focus": "var(--fg-focus)",
	"fg-focus-inverse": "var(--fg-focus-inverse)",
	"fg-text-link": "var(--fg-text-link)",
	"bg-base": "var(--bg-base)",
	"bg-default": "var(--bg-default)",
	"bg-layer-one": "var(--bg-layer-one)",
	"bg-layer-two": "var(--bg-layer-two)",
	"bg-layer-three": "var(--bg-layer-three)",
	"bg-primary": "var(--bg-primary)",
	"bg-primary-strong": "var(--bg-primary-strong)",
	"bg-primary-minimal": "var(--bg-primary-minimal)",
	"bg-transparent": "var(--bg-transparent)",
	"status-positive": "var(--status-positive)",
	"status-positive-strong": "var(--status-positive-strong)",
	"status-positive-subtle": "var(--status-positive-subtle)",
	"status-positive-minimal": "var(--status-positive-minimal)",
	"status-negative": "var(--status-negative)",
	"status-negative-strong": "var(--status-negative-strong)",
	"status-negative-subtle": "var(--status-negative-subtle)",
	"status-negative-minimal": "var(--status-negative-minimal)",
	"status-alert": "var(--status-alert)",
	"status-alert-strong": "var(--status-alert-strong)",
	"status-alert-subtle": "var(--status-alert-subtle)",
	"status-alert-minimal": "var(--status-alert-minimal)",
	"status-notification": "var(--status-notification)",
	"status-informational": "var(--status-informational)",
	"status-informational-strong": "var(--status-informational-strong)",
	"status-informational-subtle": "var(--status-informational-subtle)",
	"status-informational-minimal": "var(--status-informational-minimal)",
	"highlight-accent": "var(--highlight-accent)",
	"highlight-accent-strong": "var(--highlight-accent-strong)",
	"highlight-accent-subtle": "var(--highlight-accent-subtle)",
	"highlight-accent-minimal": "var(--highlight-accent-minimal)",
	"highlight-brand": "var(--highlight-brand)",
	"highlight-one": "var(--highlight-one)",
	"highlight-two": "var(--highlight-two)",
	"highlight-three": "var(--highlight-three)",
	"highlight-four": "var(--highlight-four)",
	"rewards-background": "var(--rewards-background)"
    }
    