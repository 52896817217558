
export const flagMap = new Map();
flagMap.set(["china"], "flagChina");
flagMap.set(["saint martin","st martin","saint","martin"], "flagSaintMartin");
flagMap.set(["montserrat"], "flagMontserrat");
flagMap.set(["sint maarten","sint","maarten"], "flagSintMaarten");
flagMap.set(["curacao"], "flagCuracao");
flagMap.set(["bonaire"], "flagBonaire");
flagMap.set(["turks and caicos","turks","and","caicos"], "flagTurksAndCaicos");
flagMap.set(["martinique"], "flagMartinique");
flagMap.set(["anguilla"], "flagAnguilla");
flagMap.set(["","internationals"], "flagInternationals");
flagMap.set(["saint","vincent","grenadines"], "flagSaintVincentGrenadines");
flagMap.set(["wales"], "flagWales");
flagMap.set(["scotland"], "flagScotland");
flagMap.set(["refugee olympic team"," refugee"," olympics","refugee","olympic","team"], "flagRefugeeOlympicTeam");
flagMap.set(["individual","neutral","athletes","athlete","olympics"], "flagIndividualNeutralAthlete");
flagMap.set(["placeholder"], "flagPlaceholder");
flagMap.set(["aruba"], "flagAruba");
flagMap.set(["bhutan"], "flagBhutan");
flagMap.set(["guam"], "flagGuam");
flagMap.set(["solomon","islands"], "flagSolomonIslands");
flagMap.set(["cook","islands"], "flagCookIslands");
flagMap.set(["chinese","taipei","taiwan"], "flagChineseTaipei");
flagMap.set(["qatar"], "flagQatar");
flagMap.set(["hong","kong"], "flagHongKong");
flagMap.set(["bermuda"], "flagBermuda");
flagMap.set(["cayman","islands"], "flagCaymanIslands");
flagMap.set(["american","samoa"], "flagAmericanSamoa");
flagMap.set(["northern","ireland"], "flagNorthernIreland");
flagMap.set(["virgin","islands","us"], "flagUsVirginIslands");
flagMap.set(["guadeloupe"], "flagGuadeloupe");
flagMap.set(["venezuela"], "flagVenezuela");
flagMap.set(["yemen"], "flagYemen");
flagMap.set(["tobago","trinidad"], "flagTrinidadTobago");
flagMap.set(["afghanistan"], "flagAfghanistan");
flagMap.set(["fiji"], "flagFiji");
flagMap.set(["ecuador"], "flagEcuador");
flagMap.set(["faroe","islands"], "flagFaroeIslands");
flagMap.set(["islands","falkland"], "flagFalklandIslands");
flagMap.set(["eu","european","union"], "flagEuropeanUnion");
flagMap.set(["ethiopia"], "flagEthiopia");
flagMap.set(["estonia"], "flagEstonia");
flagMap.set(["eritrea"], "flagEritrea");
flagMap.set(["guinea","equatorial"], "flagEquatorialGuinea");
flagMap.set(["england"], "flagEngland");
flagMap.set(["el","salvador"], "flagElSalvador");
flagMap.set(["egypt"], "flagEgypt");
flagMap.set(["croatia"], "flagCroatia");
flagMap.set(["east","timor","leste","timor-leste"], "flagEastTimor");
flagMap.set(["republic","dominican"], "flagDominicanRepublic");
flagMap.set(["djibouti"], "flagDjibouti");
flagMap.set(["denmark"], "flagDenmark");
flagMap.set(["republic","congo","democratic","of"], "flagDemocraticRepublicOfCongo");
flagMap.set(["czech","republic"], "flagCzech");
flagMap.set(["cyprus"], "flagCyprus");
flagMap.set(["cuba"], "flagCuba");
flagMap.set(["cote","ivoire","ivory","coast"], "flagIvoryCoast");
flagMap.set(["costarica","costa","rica"], "flagCostaRica");
flagMap.set(["comoros"], "flagComoros");
flagMap.set(["colombia"], "flagColombia");
flagMap.set(["chile"], "flagChile");
flagMap.set(["bahrain"], "flagBahrain");
flagMap.set(["chad"], "flagChad");
flagMap.set(["burkina","faso"], "flagBurkinaFaso");
flagMap.set(["central","republic","african"], "flagCentralAfricanRepublic");
flagMap.set(["cape","verde"], "flagCapeVerde");
flagMap.set(["canada"], "flagCanada");
flagMap.set(["cameroon"], "flagCameroon");
flagMap.set(["cambodia"], "flagCambodia");
flagMap.set(["belgium"], "flagBelgium");
flagMap.set(["burundi"], "flagBurundi");
flagMap.set(["burma"], "flagBurma");
flagMap.set(["myanmar"], "flagMyanmar");
flagMap.set(["bulgaria"], "flagBulgaria");
flagMap.set(["brunei"], "flagBrunei");
flagMap.set(["brazil"], "flagBrazil");
flagMap.set(["botswana"], "flagBotswana");
flagMap.set(["bosnia","herzegovina"], "flagBosnia");
flagMap.set(["bolivia"], "flagBolivia");
flagMap.set(["benin"], "flagBenin");
flagMap.set(["belize"], "flagBelize");
flagMap.set(["belarus"], "flagBelarus");
flagMap.set(["barbados"], "flagBarbados");
flagMap.set(["bangladesh"], "flagBangladesh");
flagMap.set(["bahamas"], "flagBahamas");
flagMap.set(["azerbaijan"], "flagAzerbaijan");
flagMap.set(["albania"], "flagAlbania");
flagMap.set(["austria"], "flagAustria");
flagMap.set(["australia"], "flagAustralia");
flagMap.set(["armenia"], "flagArmenia");
flagMap.set(["argentina"], "flagArgentina");
flagMap.set(["antigua","barbuda"], "flagAntiguaBarbuda");
flagMap.set(["angola"], "flagAngola");
flagMap.set(["andorra"], "flagAndorra");
flagMap.set(["algeria"], "flagAlgeria");
flagMap.set(["finland"], "flagFinland");
flagMap.set(["france","martinique","guadeloupe"], "flagFrance");
flagMap.set(["island","british","virgin","islands"], "flagBritishVirginIslands");
flagMap.set(["puerto","rico"], "flagPuertoRico");
flagMap.set(["swaziland","eswatini"], "flagSwaziland");
flagMap.set(["swaziland","eswatini"], "flagEswatini");
flagMap.set(["zimbabwe"], "flagZimbabwe");
flagMap.set(["zambia"], "flagZambia");
flagMap.set(["french","guiana"], "flagFrenchGuiana");
flagMap.set(["vietnam"], "flagVietnam");
flagMap.set(["usa","united states of america","america","us","united states","united","states","of"], "flagUnitedStatesOfAmerica");
flagMap.set(["guinea-bissau","guinea","bissau"], "flagGuineaBissau");
flagMap.set(["vanuatu"], "flagVanuatu");
flagMap.set(["uzbekistan"], "flagUzbekistan");
flagMap.set(["uruguay"], "flagUruguay");
flagMap.set(["arab","emirates","united"], "flagUnitedArabEmirates");
flagMap.set(["ukraine"], "flagUkraine");
flagMap.set(["united","kingdom","uk","great","britain"], "flagUnitedKingdom");
flagMap.set(["uganda"], "flagUganda");
flagMap.set(["tuvalu"], "flagTuvalu");
flagMap.set(["turkmenistan"], "flagTurkmenistan");
flagMap.set(["turkey"], "flagTurkey");
flagMap.set(["tunisia"], "flagTunisia");
flagMap.set(["gabon"], "flagGabon");
flagMap.set(["togo"], "flagTogo");
flagMap.set(["tonga"], "flagTonga");
flagMap.set(["thailand"], "flagThailand");
flagMap.set(["tanzania"], "flagTanzania");
flagMap.set(["tajikistan"], "flagTajikistan");
flagMap.set(["taiwan"], "flagTaiwan");
flagMap.set(["syria"], "flagSyria");
flagMap.set(["swiss","switzerland"], "flagSwitzerland");
flagMap.set(["sweden"], "flagSweden");
flagMap.set(["suriname"], "flagSuriname");
flagMap.set(["sudan"], "flagSudan");
flagMap.set(["lanka","sri"], "flagSriLanka");
flagMap.set(["somalia"], "flagSomalia");
flagMap.set(["spain"], "flagSpain");
flagMap.set(["south","sudan"], "flagSouthSudan");
flagMap.set(["islands","south","sandwich"], "flagSouthSandwichIslands");
flagMap.set(["south","africa"], "flagSouthAfrica");
flagMap.set(["slovenia"], "flagSlovenia");
flagMap.set(["slovakia"], "flagSlovakia");
flagMap.set(["singapore"], "flagSingapore");
flagMap.set(["leone","sierra"], "flagSierraLeone");
flagMap.set(["seychelles"], "flagSeychelles");
flagMap.set(["serbia"], "flagSerbia");
flagMap.set(["senegal"], "flagSenegal");
flagMap.set(["arabia","saudi"], "flagSaudiArabia");
flagMap.set(["and","principe","sao","tome","são","tomé","príncipe"], "flagSaoTomeAndPrincipe");
flagMap.set(["marino","san"], "flagSanMarino");
flagMap.set(["samoa"], "flagSamoa");
flagMap.set(["saint","lucia"], "flagSaintLucia");
flagMap.set(["kitts","nevis","saint"], "flagSaintKittsNevis");
flagMap.set(["rwanda"], "flagRwanda");
flagMap.set(["russia"], "flagRussia");
flagMap.set(["romania"], "flagRomania");
flagMap.set(["republic","congo","of","the"], "flagRepublicOfTheCongo");
flagMap.set(["quatar"], "flagQuatar");
flagMap.set(["portugal"], "flagPortugal");
flagMap.set(["poland"], "flagPoland");
flagMap.set(["philippines"], "flagPhilippines");
flagMap.set(["peru"], "flagPeru");
flagMap.set(["paraguay"], "flagParaguay");
flagMap.set(["new","guinea","papua"], "flagPapuaNewGuinea");
flagMap.set(["palau"], "flagPalau");
flagMap.set(["panama"], "flagPanama");
flagMap.set(["palestine"], "flagPalestine");
flagMap.set(["norway"], "flagNorway");
flagMap.set(["pakistan"], "flagPakistan");
flagMap.set(["oman"], "flagOman");
flagMap.set(["korea","north"], "flagNorthKorea");
flagMap.set(["nigeria"], "flagNigeria");
flagMap.set(["niger"], "flagNiger");
flagMap.set(["nicaragua"], "flagNicaragua");
flagMap.set(["new","zealand"], "flagNewZealand");
flagMap.set(["netherlands"], "flagNetherlands");
flagMap.set(["nepal"], "flagNepal");
flagMap.set(["nauru"], "flagNauru");
flagMap.set(["namibia"], "flagNamibia");
flagMap.set(["mozambique"], "flagMozambique");
flagMap.set(["montenegro"], "flagMontenegro");
flagMap.set(["mongolia"], "flagMongolia");
flagMap.set(["monaco"], "flagMonaco");
flagMap.set(["moldova"], "flagMoldova");
flagMap.set(["micronesia","federated","states","of"], "flagMicronesia");
flagMap.set(["mexico"], "flagMexico");
flagMap.set(["islands","marshall"], "flagMarshallIslands");
flagMap.set(["mauritius"], "flagMauritius");
flagMap.set(["mauritania"], "flagMauritania");
flagMap.set(["madagascar"], "flagMadagascar");
flagMap.set(["marocco","morocco"], "flagMorocco");
flagMap.set(["malta"], "flagMalta");
flagMap.set(["maldives"], "flagMaldives");
flagMap.set(["mali"], "flagMali");
flagMap.set(["malaysia"], "flagMalaysia");
flagMap.set(["malawi"], "flagMalawi");
flagMap.set(["macedonia","north"], "flagMacedonia");
flagMap.set(["luxembourg"], "flagLuxembourg");
flagMap.set(["lithuania"], "flagLithuania");
flagMap.set(["liechtenstein"], "flagLiechtenstein");
flagMap.set(["libya"], "flagLibya");
flagMap.set(["liberia"], "flagLiberia");
flagMap.set(["lesotho"], "flagLesotho");
flagMap.set(["lebanon"], "flagLebanon");
flagMap.set(["greece"], "flagGreece");
flagMap.set(["latvia"], "flagLatvia");
flagMap.set(["laos"], "flagLaos");
flagMap.set(["kyrgyzstan"], "flagKyrgyzstan");
flagMap.set(["kuwait"], "flagKuwait");
flagMap.set(["kosovo"], "flagKosovo");
flagMap.set(["korea","south"], "flagKorea");
flagMap.set(["kiribati"], "flagKiribati");
flagMap.set(["kenya"], "flagKenya");
flagMap.set(["kazakhstan"], "flagKazakhstan");
flagMap.set(["jordan"], "flagJordan");
flagMap.set(["jersey"], "flagJersey");
flagMap.set(["japan"], "flagJapan");
flagMap.set(["jamaica"], "flagJamaica");
flagMap.set(["italy"], "flagItaly");
flagMap.set(["israel"], "flagIsrael");
flagMap.set(["man","of","isle"], "flagIsleOfMan");
flagMap.set(["ireland"], "flagIreland");
flagMap.set(["iraq"], "flagIraq");
flagMap.set(["iran"], "flagIran");
flagMap.set(["indonesia"], "flagIndonesia");
flagMap.set(["india"], "flagIndia");
flagMap.set(["iceland"], "flagIceland");
flagMap.set(["hungary"], "flagHungary");
flagMap.set(["honduras"], "flagHonduras");
flagMap.set(["haiti"], "flagHaiti");
flagMap.set(["guyana"], "flagGuyana");
flagMap.set(["guinea"], "flagGuinea");
flagMap.set(["gambia"], "flagGambia");
flagMap.set(["guernsey"], "flagGuernsey");
flagMap.set(["guatemala"], "flagGuatemala");
flagMap.set(["grenada"], "flagGrenada");
flagMap.set(["germany"], "flagGermany");
flagMap.set(["gibraltar"], "flagGibraltar");
flagMap.set(["ghana"], "flagGhana");
flagMap.set(["georgia"], "flagGeorgia");
flagMap.set(["dominica","caribbean","island"], "flagDominica");
