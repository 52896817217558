import React from "react";
import { Icon, Body, TeamLogo } from "@caesars-digital/caesars-ui";
import { useIonToast } from '@ionic/react';
import "./IconCard.css";

const IconCard = ({ icon, useTeamLogo, activeSport }) => {
    const [present] = useIonToast();
    const presentToast = () => {
        navigator.clipboard.writeText(icon.name);
        present({
          message: `Icon name "${icon.name}" copied`,
          duration: 1500,
          position: 'bottom',
          cssClass: 'custom-toast',
          animted: true
        });
      };
  return (
    <div className="group flex-col items-center justify-center focus-visible:outline-none" role="cell" tabIndex="1" onClick={presentToast}>
      <div className="peer px-xl py-lg h-[calc(100%-24px)] border border-1 border-solid border-fg-moderate shadow-2 rounded-1 flex box-border items-center justify-center hover:ring-1 hover:ring-fg-moderate hover:cursor-pointer active:bg-bg-layer-two group-focus-visible:ring-2 group-focus-visible:ring-fg-focus group-focus-visible:ring-offset-2">
        { useTeamLogo ? <TeamLogo name={icon.name} size="2xl" sport={activeSport} /> : <Icon name={icon.name} size="2xl"/>}
      </div>
      <Body size="xs" className="!m-0 !my-1 text-center peer-hover:font-bold break-words">{icon.name}</Body>
    </div>
  );
};

export default IconCard;
