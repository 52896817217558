import React from "react";

export const Pill = ({ colors, children }) => {
  return <div className={`inline-block w-auto text-center px-4 rounded-6 text-size-3 font-bold ${colors}`}>{children}</div>
}

export const StatusPill = ({ status }) => {
  if (!status)
    return <hr className="inline-block w-4 h-2px !my-0 mx-auto p-0 bg-gray-500" />;
  const _status = status.toLowerCase();
  if (_status === "planned")
    return (<Pill colors="text-turquoise-100 bg-turquoise-700">Planned</Pill>);
  if (_status === "in progress")
    return (<Pill colors="text-blue-100 bg-blue-700">In Progress</Pill>);
  if (_status === "ready")
    return (<Pill colors="text-green-100 bg-green-700">Ready</Pill>);
  if (_status === "partially ready")
    return (<Pill colors="text-yellow-100 bg-yellow-700">Partially Ready</Pill>);
  if (_status === "deprecated")
    return (<Pill colors="text-red-100 bg-red-700">Deprecated</Pill>);
  if (_status === "not planned")
    return (<Pill colors="text-gray-100 bg-gray-800">Not Planned</Pill>);
  if (_status === "in qa")
    return (<Pill colors="text-purple-100 bg-purple-600">In QA</Pill>);
};
