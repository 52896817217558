import React, { memo } from "react";
import Logo from "../Logo/Logo";
import Menu from "../Menu/Menu";
import { Link } from "gatsby";
import { useRootQuery } from "~/context/RootQuery/RootQuery";
import { cloneDeep } from "lodash";

const ignoredMenuSlugs = ["download/"];

const Nav = ({ theme }) => {
  const {menuData} = cloneDeep(useRootQuery());
  const filteredMenuData = cloneDeep(menuData).allMdx.edges.filter(
    (e) => !ignoredMenuSlugs.includes(e.node.slug)
  );
  return (
    <nav className="flex flex-col w-[320px] h-auto px-[30px] py-10 bg-bg-layer-one">
      <Link to="/">
        <Logo theme={theme} />
      </Link>
      <Menu menuData={filteredMenuData} />
      <div className="text-fg-primary-strong">
        <Link to="/help-feedback">Help &amp; Feedback</Link>
      </div>
    </nav>
  );
};

export default memo(Nav);
