import React from "react";
import CzrLink from "~/components/CustomMDX/CzrLink/CzrLink";
import cx from "@caesars-digital/caesars-ui/dist/utils/cx";
import { StatusPill } from "../../../StatusPill/StatusPill";

const Col = ({ children, is }) => {
  return (
    <div
      className={cx(
        {
          "text-center": is !== "name",
        },
        "border-b-1 border-solid border-fg-minimal py-4 text-size-6"
      )}
    >
      {children}
    </div>
  );
};


const ComponentListRow = ({ data }) => {
  return (
    <>
      <Col is="name">
        {data.Documentation === "Ready" ? (
          <CzrLink to={`/components/${data.Slug}`} className="!text-size-6">{data.Name}</CzrLink>
        ) : (
          data.Name
        )}
      </Col>
      <Col><StatusPill status={data.Figma_Library} /></Col>
      <Col><StatusPill status={data.Documentation} /></Col>
      <Col><StatusPill status={data.Web} /></Col>
      <Col><StatusPill status={data.iOS} /></Col>
      <Col><StatusPill status={data.Android} /></Col>
    </>
  );
};

export default ComponentListRow;
