import { useState, useEffect } from "react";

export const useDynamicCUIImport = () => {
    const [data, setData] = useState({});
  
    useEffect(()=>{
      const fetchData = async () => { 
        if (!Object.keys(data).length) {
            const data = await import(`@caesars-digital/caesars-ui`);
            setData(data);
        }
      }
      fetchData();
    },[]);

    return data;
}