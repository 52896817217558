import { useState, useEffect } from "react";

export const useStorybookMdx = (component) => {
    const [data, setData] = useState({});
  
    useEffect(()=>{
      const fetchData = async () => { 
        if (!Object.keys(data).length) {
            const path = "parent" in component ? component.parent : component.code;
            try {
              const data = await import(`../../../../packages/components/src/components/${path}/${path}.stories.mdx`);
              setData(data);
            } catch (e) {
              console.log(e);
            }

        }
        // console.log(data, component);
      }
      fetchData();
    },[]);

    return data;
}
export default useStorybookMdx;
