import React from "react";
import cx from "~/utils/cx";
export const ColorSwatch = ({ value, theme }) => {
    return <div className={cx({
        'ring-gray-300': theme === 'czr-light',
        'ring-gray-dark-800': theme === 'czr-dark'
    },"w-[56px] h-[56px] rounded-[6px] ring-1")} style={{ background: `${value}`}}></div>
};

export default ColorSwatch;
