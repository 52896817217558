import React from "react";
import { Grid } from "../../Grid/Grid";
import { Icon } from "../../Icon/Icon";
import { Heading, Body } from "../../Typography/Typography";
import { CardHeadProps } from "../types";
import { Details } from "../../Details/Details";
import cx from "components/src/utils/cx";
import {
  tailwindSemanticBgColors,
} from "../../../shared/styles/colorStyles";

export const CardHead = ({
  title,
  description,
  actionText,
  details = [], 
  onClick,
  collapsible,
  bg = "bg-default",
  fg = "fg-default",
  headingSize = "md-bold",
  icon,
  isOpen,
}: CardHeadProps) => {
  if (!title && !description && !actionText && !details.length) return null;
  return (
    <Grid
      gap="none"
      className={cx(
        {
          [tailwindSemanticBgColors[bg]]: true,
        },
        "cui__card-header cui-py-sm cui-px-md hover:cui-bg-bg-layer-one active:cui-bg-bg-layer-two cui-cursor-pointer"
      )}
      flush
      onClick={onClick}
    >
      <Grid.Cell colSpan={9} className="cui-flex-col">
        {title ? (
          <div className="cui-flex cui-items-center" role="presentation">
            { icon ? <Icon name={icon} className="cui-mr-1" /> : null}
            <Heading
              size={headingSize}
              role="heading"
              aria-level={1}
              className="cui-select-none"
              color={fg}
            >
              {title}
            </Heading>
          </div>
        ) : null}
        {description ? (
          <Body size="sm" color={fg ? fg : "fg-moderate"} tag="div">
            {description}
          </Body>
        ) : null}
      </Grid.Cell>
      <Grid.Cell colSpan={3} items="center">
        <span className="cui-w-full cui-inline-flex cui-justify-end">
          {details.length ? <Details list={details} /> : null }
          {actionText ?<Body tag="span" size="sm-bold" color={fg}>
            {actionText}
          </Body> : null}
          {collapsible ? (
            <Icon
              name="ui_chevron-up"
              size="md"
              color={fg ? fg : "fg-moderate"}
              className={cx(
                {
                  "cui-rotate-180": !isOpen,
                },
                "cui-transition-transform cui-duration-200 cui-ml-1"
              )}
            />
          ) : null}
        </span>
      </Grid.Cell>
    </Grid>
  );
};

CardHead.displayName = "CardHeader";