import { createSlice } from "@reduxjs/toolkit";

const isClient = typeof window !== "undefined";
let savedTheme = "czr-light";
let defaultPlatform = "Web (CSS)";
let defaultSemanticTheme = "Caesars Light";
if (isClient) {
  savedTheme = localStorage.getItem("theme") || "czr-light";
  defaultPlatform = localStorage.getItem("platform") || "Web (CSS)";
  defaultSemanticTheme = localStorage.getItem("semanticTheme") || "Caesars Light";
}

const initialState = {
  theme: savedTheme,
  platform: defaultPlatform,
  semanticTheme: defaultSemanticTheme,
  version: ""
};

// Slice
const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setTheme: (state, action) => {
      const { payload } = action;
      isClient && localStorage.setItem("theme", payload);
      state.theme = payload;
    },
    setPlatform: (state, action) => {
      const { payload } = action;
      isClient && localStorage.setItem("platform", payload);
      state.platform = payload;
    },
    setSemanticTheme: (state, action) => {
      const { payload } = action;
      isClient && localStorage.setItem("semanticTheme", payload);
      state.semanticTheme = payload;
    },
    setVersion: (state, action) => {
      const { payload } = action;
      state.version = payload;
    }
  },
});

// Reducers
export default globalSlice.reducer;

// Selectors
export const globalSelector = (state) => state.global;
export const themeSelector = (state) => state.global.theme;
export const platformSelector = (state) => state.global.platform;
export const semanticThemeSelector = (state) => state.global.semanticTheme;
export const versionSelector = (state) => state.global.version

// Actions
export const { setTheme, setPlatform, setSemanticTheme, setVersion } = globalSlice.actions;

// Thunks
// export const toggleThemeMode = (): Thunk => (dispatch: Dispatch) => {
//   const { themeMode } = store.getState().ui;
//   const mode = themeMode === ThemeMode.LIGHT ? ThemeMode.DARK : ThemeMode.LIGHT;

//   dispatch(setThemeMode(mode));
// };
