import React from "react";
import { CardProps } from "../types";
import { CardSection } from './CardSection';

export const CardPreFooter = ({ children, ...rest }: Partial<CardProps>) => (
  <CardSection x="cui-px-md" {...rest}>
    {children}
  </CardSection>
);

CardPreFooter.displayName = "CardPreFooter";