// core
import originalColors from "tokens/build/js/core";
// semantic
import lightColors from "tokens/build/js/czr-light";
import darkColors from "tokens/build/js/czr-dark";
import whLightColors from "tokens/build/js/wh-light";
import whDarkColors from "tokens/build/js/wh-dark";
import czrPalaceLightColors from "tokens/build/js/czr-palace-light";
import czrPalaceDarkColors from "tokens/build/js/czr-palace-dark";
import horseshoeLightColors from "tokens/build/js/horseshoe-light";
import horseshoeDarkColors from "tokens/build/js/horseshoe-dark";
import { cloneDeep } from "lodash";

const colors = cloneDeep(originalColors);

for (let i in colors.core) {
  if (["gradient", "rogue", "roboto-flex-variables", "spacing", "sf-pro-condensed-width", "border-width", "border-radius", "box-shadow", "font-family", "font-size", "line-height", "letter-spacing", "box-shadow", "font-weight", "uppercase"].includes(i)) {
    delete colors.core[i]
  }
}

const stops = Object.keys(colors.core.blue).reduce(
  (obj, key) => ({ ...obj, [key]: { value: key } }),
  {}
);
colors.core = { stops: stops, ...colors.core };


export const flattenSet = (colorSet) =>
  Object.entries(colorSet).reduce((set, [name, obj]) => {
    if ("name" in obj) {
      set.push(obj);
    } else {
      set.push(...flattenSet(obj));
    }
    return set;
  }, []).flat();


export const colorSetsCore = Object.entries(colors.core).reduce(
  (acc, [name, set]) => {
    if (name === "stops") {
      acc["lightPalette"].push([name, set, { sortOrder: 0}]);
      acc["darkPalette"].push([name, set, { sortOrder: 0}]);
      return acc;
    }
    if (["brand", "rewards"].includes(name)) {
      acc["brandPalette"].push([name, set]);
      return acc;
    }
    if (name === "neutrals") {
      acc["neutralsPalette"].push([name, set]);
      return acc;
    }
    if (/-dark/g.test(name)) {
      acc["darkPalette"].push([name, set]);
    } else {
      acc["lightPalette"].push([name, set]);
    }
    return acc;
  },
  { brandPalette: [], neutralsPalette: [], darkPalette: [], lightPalette: [] }
);


  export const semanticSets = { 
    "czrLight": flattenSet(lightColors),
    "czrDark": flattenSet(darkColors),
    "whLight": flattenSet(whLightColors),
    "whDark": flattenSet(whDarkColors),
    "czrPalaceLight": flattenSet(czrPalaceLightColors),
    "czrPalaceDark": flattenSet(czrPalaceDarkColors),
    "horseshoeLight": flattenSet(horseshoeLightColors),
    "horseshoeDark": flattenSet(horseshoeDarkColors)
   };
