import React from "react";
import { ButtonGroup } from "@caesars-digital/caesars-ui";
import { useDispatch, useSelector } from "react-redux";
import { setSemanticTheme, semanticThemeSelector } from "~/state/globalSlice";

const items = [
  {
    itemId: 0,
    label: "Caesars Light",
  },
  {
    itemId: 1,
    label: "Caesars Dark",
  },
  {
    itemId: 2,
    label: "William Hill Light",
  },
  {
    itemId: 3,
    label: "William Hill Dark",
  },
  {
    itemId: 4,
    label: "Caesars Palace Light",
  },
  {
    itemId: 5,
    label: "Caesars Palace Dark",
  },
];

const SemanticThemeSwitcher = ({ handleToggle, ...rest }) => {
  const dispatch = useDispatch();
  const platform = useSelector(semanticThemeSelector);
  const handleClick = (e, id) => {
    const [selectedItem] = items.filter((i) => i.itemId === id);
    dispatch(setSemanticTheme(selectedItem.label));
  };
  const activeItem = items.filter((item) => item.label === platform)[0].itemId;
  return (
    <ButtonGroup
      items={items}
      initialItem={activeItem}
      handleClick={handleClick}
      {...rest}
    />
  );
};

export default SemanticThemeSwitcher;
