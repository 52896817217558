import React from "react";
import { twClassMap } from "../js/twClassMaps";
import cx from "~/utils/cx";

export const ExampleColumn = ({ children }) => (
    <div className="flex flex-row items-center justify-start w-auto h-full min-h-[74px] py-5 pr-2xl border-b-1 border-solid border-fg-minimal">
      {children}
    </div>
  );

// These are the little graphical representations of the tokens

export const examples = {
    Spacing: (data, tokenGroup) => {
      return (
        <ExampleColumn>
          <div className={`w-4 h-4 bg-bg-primary`}></div>
          <div
            className={`bg-bg-layer-one h-4 ${
              twClassMap[tokenGroup][data.name]
            }`}
          ></div>
          <div className="w-4 h-4 bg-bg-primary"></div>
        </ExampleColumn>
      );
    },
    Colors: (data, tokenGroup, theme) => {
      return (
        <div className="py-2 border-b-1 border-solid border-fg-minimal h-full flex items-center">
          <div
            className={cx(
              {
                "border-gray-300": theme === "czr-light",
                "border-gray-800": theme === "czr-dark",
              },
              "w-14 h-14 rounded-2 border-1 border-solid bg-bg-primary-strong mr-2xl"
            )}
            style={{ background: data.value }}
          ></div>
        </div>
      );
    },
    BorderWidth: (data, tokenGroup) => {
      return (
        <ExampleColumn>
          <div
            className={`py-2 ${
              twClassMap[tokenGroup][data.name]
            } border-solid border-fg-primary h-full flex items-center w-20 h-4 bg-bg-primary-minimal`}
          ></div>
        </ExampleColumn>
      );
    },
    BorderRadius: (data, tokenGroup) => {
      return (
        <ExampleColumn>
          <div
            className={`py-2 ${
              twClassMap[tokenGroup][data.name]
            } h-full flex items-center w-20 h-20 bg-bg-primary`}
          ></div>
        </ExampleColumn>
      );
    },
    Shadow: (data, tokenGroup) => <ExampleColumn><div className={`w-20 h-20 bg-neturals-white ${twClassMap[tokenGroup][data.name]}`}></div></ExampleColumn>,
    FontFamily: (data, tokenGroup) => <ExampleColumn><div className={`${twClassMap[tokenGroup][data.name]}`}>Be a Caesar!</div></ExampleColumn>,
    FontSize: (data, tokenGroup) => <ExampleColumn><div className={`${twClassMap[tokenGroup][data.name]}`}>Be a Caesar!</div></ExampleColumn>,
    LineHeight: (data, tokenGroup) => <ExampleColumn><div className="bg-bg-primary-minimal border-1 border-solid border-fg-primary"><div className={`${twClassMap[tokenGroup][data.name]}`}>Be a Caesar!</div></div></ExampleColumn>,
    FontWeight: (data, tokenGroup) => <ExampleColumn><div className={`${twClassMap[tokenGroup][data.name]}`}>Be a Caesar!</div></ExampleColumn>,
    LetterSpacing: (data, tokenGroup) => <ExampleColumn><div className={`${twClassMap[tokenGroup][data.name]}`}>Be a Caesar!</div></ExampleColumn>
  };