import React, { memo } from "react";
import MenuItem from "./components/MenuItem";
import cx from "~/utils/cx";
import buildMenu from "./js/buildMenu";

function sortMenu(a, b) {
  return a.frontmatter.sortOrder < b.frontmatter.sortOrder ? -1 : 1;
}

const hiddenSlugs = ["whats-new-archive"];

const Menu = ({ data, hasSubmenu = false, menuData, isOpen = false}) => {
  const transformedMenu = hasSubmenu
    ? Object.values(data).sort(sortMenu)
    : Object.values(buildMenu(menuData)).sort(sortMenu);
  return ( ((hasSubmenu && isOpen) || !hasSubmenu) &&
    <ul
      className={cx(
        {
          "mt-8": !hasSubmenu,
          "mt-2": hasSubmenu,
          "invisible": !isOpen && hasSubmenu,
          "visible": isOpen && hasSubmenu,
        },
        "grow"
      )}
    >
      {transformedMenu?.map((item) => {
        return (
          item.slug && !hiddenSlugs.includes(item.slug) && (
            <MenuItem key={item.id} {...item} hasSubmenu={hasSubmenu} />
          )
        );
      })}
    </ul>
  );
};

export default memo(Menu);
