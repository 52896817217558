import { AnimationStyleType } from "../types";

export enum AnimationStyles {
  "pulse",
  "ping",
  "spin",
  "bounce"
}
export const AnimationStylesArr = Object.values(AnimationStyles).filter(
  (v) => typeof v === "string"
);

export const animationStyles = (animate: AnimationStyleType) => {
  if (!animate || !AnimationStylesArr.includes(animate)) return "";
  return {
    spin: "cui-animate-spin",
    pulse: "cui-animate-pulse",
    ping: "cui-animate-ping",
    bounce: "cui-animate-bounce"
  }[animate];
};
