import React, {useMemo} from "react";
import { useDispatch, useSelector } from 'react-redux';
import { setTheme, themeSelector } from "~/state/globalSlice";
import { ButtonGroup } from "@caesars-digital/caesars-ui";
import CButton from "../CButton/CButton";

const themeMap = {0: 'czr-light', 1: 'czr-dark'};
export const Header = () => {
    const dispatch = useDispatch();
    const theme = useSelector(themeSelector);
    const handleClick = (e, id) => {
        dispatch(setTheme(themeMap[id]))
    }
    const activeThemeId = useMemo(() => {
        const [id] = Object.entries(themeMap).filter(([id, label]) => label === theme)[0]
        return +id;
    }, [theme])

    return (<div className="flex items-center justify-start gap-8">
        <div className="grow"></div>
        <ButtonGroup items={[{ itemId: 0, label: 'Light Theme'}, { itemId: 1, label: 'Dark Theme'}]} handleClick={handleClick} initialItem={activeThemeId} />
        <div><CButton link="/getting-started">Get Started</CButton></div>
    </div>)
}


export default Header;