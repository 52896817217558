import React from "react";
import cx from "~/utils/cx";
const TableRowColumn = ({ children, size = null, ...rest }) => (
  <div
    className={cx(
      {
        "min-w-[200px]": size === null,
        "min-w-[116px]": size === "sm",
      },
      "flex flex-row items-center h-full p-4 pl-0 border-b-1 border-solid border-fg-minimal"
    )}
    {...rest}
  >
    {children}
  </div>
);

export default TableRowColumn;
