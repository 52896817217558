import React, { useState, useMemo, useEffect, useLayoutEffect, useRef, isValidElement } from "react";
import { createPortal } from "react-dom";
import SelectMenu from "../SelectMenu/SelectMenu";
import cx from "@caesars-digital/caesars-ui/dist/utils/cx";
import { Loading } from "@caesars-digital/caesars-ui";
import { LiveProvider, LiveEditor, LiveError, LivePreview } from "react-live";
import useComponents from "~/hooks/useAllComponents";
import {
  StorybookIcon,
  StickerSheetIcon,
  CodeIcon,
  CopyIcon,
} from "../Icons/Icons";
import codeTheme from "prism-react-renderer/themes/vsDark";
import { themes as _themeList } from "tokens/build/js/themes-list";
import escape from "lodash/escape";
import cloneDeep from "lodash/cloneDeep";
import { useSharedStorybook } from "~/hooks/useSharedStorybook";
import { useDynamicCUIImport } from "~/hooks/useDynamicCUIImports";
import { useStorybookMdx } from "~/hooks/useStorybookMdx";

import toJsxString from "react-element-to-jsx-string";

const CustomIframe = ({ children, example, toggleLoading, ...props }) => {
  const [contentRef, setContentRef] = useState(null);
  const headNode = contentRef?.contentWindow?.document?.head;
  const mountNode = contentRef?.contentWindow?.document?.body;
  let wrapper = mountNode && mountNode.querySelector("#live-preview");
  const isBrowser = typeof window !== "undefined";
  useLayoutEffect(() => {
    if (!wrapper || !contentRef) return;
    setTimeout(() => {
      const wrapperHeight = String(window.getComputedStyle(wrapper).height);
      const wrapperHeightNum = Number(wrapperHeight.replace('px', ''));
      contentRef.style.height = wrapperHeightNum < 120 ? '120px' : wrapperHeight;
      setTimeout(toggleLoading(false), 100)
    }, 500);
  }, [wrapper, example]);

  if (isBrowser) {
    const link = isBrowser && document.createElement("link");
    const globalCss =
      document.querySelector(`[data-identity="gatsby-global-css"]`)?.dataset
        .href || null;
    link.href = globalCss || `/commons.css`;
    link.rel = "stylesheet";
    link.type = "text/css";
    link.id = "iframe-css";

    const style = isBrowser && document.createElement("style");
    style.id = "iframe-style";
    style.innerHTML = `body, html { margin: 0; padding: 0; overflow: hidden; height: auto !important; background: var(--bg-default);}`;

    headNode &&
      !headNode.querySelector("#iframe-css") &&
      headNode.appendChild(link);
    headNode &&
      !headNode.querySelector("#iframe-style") &&
      headNode.appendChild(style);
  }
  return (
    <iframe {...props} ref={setContentRef} sandbox="allow-same-origin">
      {mountNode && createPortal(children, mountNode)}
    </iframe>
  );
};

export const IconCircle = ({ children, helperText, onClick }) => {
  return (
    <div
      role="presentation"
      tabIndex="-1"
      before={helperText}
      onClick={onClick}
      className="relative w-10 h-10 transition-all rounded-[100%] hover:bg-bg-layer-two focus:ring-2 focus:ring-offset-1 focus:ring-fg-focus flex items-center justify-center cursor-pointer before:hidden hover:before:flex before:w-auto before:absolute before:content-[attr(before)] before:bg-bg-primary-strong before:text-fg-inverse before:rounded-2 before:whitespace-nowrap before:top-[-40px] before:p-2 before:text-size-1"
    >
      {children}
    </div>
  );
};

const platformObjs = [
  { name: "Web (Cordova)", value: "web", prop: "Web" },
  { name: "Native iOS", value: "ios", prop: "iOS" },
];
const htmlElements = ["div", "span", "main", "article", "img", "picture", "ol", "ul", "li", "table", "tbody", "tr", "td", "th", "i", "h1", "h2", "h3", "h4", "h5", "h6"]

function parseJsx(obj) {
  if (typeof obj !== "object") return;
  for (let k in obj) {
    if (
      isValidElement(obj[k])
    ) {
      let data = obj[k];
      obj[k] = `<${data?.type?.displayName || data?.type?.name || data?.type} ${objToProps(
        data?.props
      )} />`;
    } else {
      parseJsx(obj[k]);
    }
  }
}

function objToProps(obj) {
  if (typeof obj !== "object") return;
  const _obj = cloneDeep(obj);
  parseJsx(_obj);
  return Object.entries(_obj)
    .map(([name, data]) => {
      let value;
      switch (typeof data) {
        case "string":
          if (/^</.test(data)) {
            value = `{${data}}`;
          } else {
            value = `"${escape(data)}"`;
          }
          break;
        case "object":
          if (Array.isArray(data)) {
            value = `{${JSON.stringify(data)
              .replace(/"</g, "(<")
              .replace(/>"/g, ">)")}}`;
            break;
          }
          if (data && ("displayName" in data && "props" in data)) {
            value = `{<${data?.type?.displayName} ${objToProps(data?.props)} />}`;
          } else {
            value = `{${JSON.stringify(data)}}`;
          }
          break;
        case "function": 
          value = `{${name}}`
          break;
        default:
          value = `{${data}}`;
      }
      return !!data ? `${name}=${value}` : "";
    })
    .join(" ");
}

export const LivePlayground = ({
  storybookLink,
  componentName,
  exampleChildren = null,
  wrapper = null,
  wrapperCond = [],
  asEmbed
}) => {
  // hooks
  const shared = useSharedStorybook(componentName);
  const { stories: examples, _exampleChildren } = shared;

  const CUI = useDynamicCUIImport();
  const storybook = useStorybookMdx(componentName);

  const component =
    useComponents()
      .allAirtableComponents.edges.map((e) => e.node.data)
      .filter(
        (d) =>
          d.Type === "Component" &&
          d.Name.toLowerCase() === componentName.display?.toLowerCase()
      )[0] || null;
      
  // state
  const [showCode, setShowCode] = useState(true);
  const [code, setCode] = useState("");
  const [copyCodeText, setCopyCodeText] = useState("Copy Code");  
  const [firstExample, setFirstExample] = useState("");
  const [selectedExample, setSelectedExample] = useState(firstExample);
  const [selectedTheme, setSelectedTheme] = useState("czr-light");
  const [loading, setLoading] = useState(true);


  let platforms = {};

  platformObjs.forEach((platform) => {
    if (
      component &&
      platform.prop in component &&
      component[platform.prop] === "Ready"
    ) {
      platforms[platform.name] = platform.value;
    }
  });

  useEffect(() => {
    const codeFn = (args = {}) => {
      const children = exampleChildren ? exampleChildren : _exampleChildren ? _exampleChildren : null;
      let codeTemplate = `<${componentName.code}${
        !!Object.keys(args).length ? " " : ""
      }${objToProps(args)}>${children ? children : ""}</${
        componentName.code
      }>`;
      // console.log({codeTemplate})
      if (wrapper && wrapperCond.some((c) => c === selectedExample)) {
        codeTemplate = `<Wrapper>${codeTemplate}</Wrapper>`;
      }
      return codeTemplate;
    };
    if (examples && storybook) {
      // setCode(codeFn(examples[selectedExample]));
      if ("Template" in storybook) {
        const opts = {
          filterProps: ["originalType", "mdxType"],
          functionValue: (fn) => {
            return fn.name
          },
          displayName(el) {
            let name = "";
            if (el.props && "mdxType" in el.props) {
              name = el.props.mdxType;
            } else if (typeof el.type === "string" && htmlElements.includes(el.type)) {
              name = el.type
            }        
            return name
          },
          showDefaultProps: false,
          showFunctions: true
        }
        const code = toJsxString(storybook.Template(examples[selectedExample]), opts);
        const wrappedCode = wrapper && wrapperCond.some((c) => c === selectedExample) ? `<Wrapper>${code}</Wrapper>` : code;
        setCode(wrappedCode);
      } else {
        setCode(codeFn(examples[selectedExample]));
      }
        
      if (!selectedExample) {
        const [[_firstExample]] = Object.entries(examples);
        setFirstExample(_firstExample)
        setSelectedExample(firstExample);
      }
    }

  }, [
    selectedExample,
    componentName.code,
    exampleChildren,
    examples,
    wrapper,
    wrapperCond,
    loading
  ]);

  const exampleKeys = examples ? Object.keys(examples) : [];
  const themes = _themeList.reduce((obj, theme) => {
    obj[theme] = theme;
    return obj;
  }, {});

  if (!examples) return <></>
  return (
    <div className="mb-20">
      <div className="flex items-center justify-between gap-6 mb-10">
        <SelectMenu
          items={platforms}
          placeholder="Platform"
          label="Platform"
          defaultValue={Object.values(platforms)[0]}
        />
        <SelectMenu
          items={exampleKeys.reduce((obj, key) => {
            obj[key] = key;
            return obj;
          }, {})}
          defaultValue={exampleKeys[0]}
          label="Example"
          onValueChange={(val) => {
            setLoading(true);
            setSelectedExample(val)}
          }
        />
        <SelectMenu
          items={themes}
          placeholder="Theme"
          label="Theme"
          defaultValue={Object.values(themes)[0]}
          onValueChange={(val) => setSelectedTheme(val)}
        />
        <div className="flex-grow"></div>
        <div className="icon-bar inline-flex gap-6">
          <IconCircle
            helperText="View in Storybook"
            onClick={() => {
              if (storybookLink) window.open(`${storybookLink}`, "_blank");
            }}
          >
            <StorybookIcon />
          </IconCircle>
          {/* <IconCircle helperText="View StickerSheet">
            <StickerSheetIcon />
          </IconCircle> */}
          <IconCircle
            helperText={showCode ? "Hide Code" : "View Code"}
            onClick={() => setShowCode((val) => !val)}
          >
            <CodeIcon />
          </IconCircle>
          <IconCircle
            helperText={copyCodeText}
            onClick={() => {
              navigator.clipboard.writeText(code);
              setCopyCodeText("Copied!");
              setTimeout(() => {
                setCopyCodeText("Copy Code");
              }, 2000);
            }}
          >
            <CopyIcon />
          </IconCircle>
        </div>
      </div>
      { asEmbed ? <iframe src={`${storybookLink}&viewMode=story&shortcuts=false&singleStory=true&toolbar=false`} width="100%" height="300"></iframe> : 
      <LiveProvider code={code} scope={{...CUI, ...examples[selectedExample], props: examples[selectedExample], Wrapper: wrapper ? wrapper : <></>, onClick: () => {}}} theme={codeTheme}>
        <div className={cx({
          "border-1 border-solid rounded-1 border-fg-subtle": loading
        },"relative")}>
        {loading && (
            <div className={`absolute inset-0 grid place-items-center w-full h-full bg-bg-layer-one ${selectedTheme} ${/dark/.test(selectedTheme) ? 'darkMode' : ''}`}>
              <Loading type="dots" variant="subtle" />
            </div>
        )}
        <CustomIframe
          className="transition-all duration-200 border-1 border-solid rounded-1 border-fg-subtle resize flex items-center justify-center bg-bg-default w-full"
          example={selectedExample}
          toggleLoading={setLoading}
        >
            <LivePreview
              id="live-preview"
              className={cx(
                {
                  [selectedTheme]: true,
                },
                "w-auto min-h-[120px] h-auto p-10 flex justify-center items-center bg-bg-default"
              )}
            />
        </CustomIframe>
        </div>
        <LiveEditor
          className={cx(
            {
              hidden: !showCode,
            },
            "text-size-5"
          )}
        />
        <LiveError />
      </LiveProvider>
      }
    </div>
  );
};

export default LivePlayground;