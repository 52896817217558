import React, { useState } from "react";
import useComponents from "~/hooks/useAllComponents";
import cx from "@caesars-digital/caesars-ui/dist/utils/cx";
import Row from "./components/ComponentListRow";
import { StatusPill } from "../../StatusPill/StatusPill";

const HeaderCol = ({ children }) => {
  return (
    <h4
      className={cx(
        {
          "text-center": children !== "Name",
        },
        "border-b-1 border-solid border-fg-default py-4"
      )}
    >
      {children}
    </h4>
  );
};

const ComponentListHeader = () => {
  return (
    <>
      <HeaderCol>Name</HeaderCol>
      <HeaderCol>Figma Library</HeaderCol>
      <HeaderCol>Documentation</HeaderCol>
      <HeaderCol>Web</HeaderCol>
      <HeaderCol>iOS</HeaderCol>
      <HeaderCol>Android</HeaderCol>
    </>
  );
};

const StatusLegend = () => {
  return (
    <div className="mb-10">
      <h4>Status Legend</h4>
      <div className="grid grid-cols-[200px,1fr,200px,1fr] items-center gap-4">
        <StatusPill status="" />
        <div>Unplanned, no status yet.</div>
        <StatusPill status="Planned" />
        <div>Planning stage complete.</div>
        <StatusPill status="Not Planned" />
        <div>No plans to work on component.</div>
        <StatusPill status="Ready" />
        <div>Published and ready for use.</div>
        <StatusPill status="Partially Ready" />
        <div>Published and working but missing features.</div>
        <StatusPill status="In Progress" />
        <div>Development and design work being done.</div>
        <StatusPill status="In QA" />
        <div>In review process.</div>
        <StatusPill status="Deprecated" />
        <div>Published, but not recommended for use (tech debt artifact).</div>
      </div>
    </div>
  );
};

const ComponentList = () => {
  const components = useComponents()
    .allAirtableComponents.edges.map((e) => e.node.data)
    .filter((d) => d.Type === "Component")
    .sort((a, b) => (a.Name < b.Name ? -1 : 1));
  const [filterTextValue, setFilterTextValue] = useState("");
  const handleFilterChange = (e) => {
    setFilterTextValue(e.target.value);
  };

  const filteredComponents = components.filter((c) =>
    filterTextValue
      ? c.Name.toLowerCase().includes(filterTextValue.toLowerCase())
      : true
  );

  return (
    <div>
      <StatusLegend />
      <div className="mt-20">
        <label htmlFor="filterText" className="mr-2">
          Filter Components
        </label>
        <input
          id="filterText"
          type="text"
          placeholder="Enter component name e.g. Button"
          className="border-1 border-solid border-fg-minimal rounded-1 min-w-[300px] p-2"
          onChange={handleFilterChange}
          value={filterTextValue}
        />
      </div>
      <div className="grid grid-cols-[300px_1fr_1fr_1fr_1fr_1fr]">
        <ComponentListHeader />
        {filteredComponents.length ? (
          filteredComponents.map((c, i) => (
            <Row data={c} key={`${c.Slug}-${i}`} />
          ))
        ) : (
          <div className="text-fg-moderate text-size-4">
            No matches, please adjust your filter text.
          </div>
        )}
      </div>
    </div>
  );
};

export default ComponentList;
