
        export default [
  "brand_mountaineer_color",
  "brand_nfl_conference_afc_color",
  "brand_nfl_playoffs_color",
  "brand_horseshoe-oc_color",
  "brand_mass-gaming-commission_color",
  "brand_nfl_conference_nfc_color",
  "brand_nba_cup_color",
  "brand_nfl_championship_color",
  "brand_nba-playoffs_color",
  "brand_mlb-championship_color",
  "brand_nfl_draft_color",
  "brand_nba-championship_color",
  "brand_horseshoe-responsible-gaming_color",
  "brand_ontario-igaming_color",
  "brand_horseshoe-color",
  "brand_nhl",
  "brand_william-hill-monogram_color",
  "brand_nfl",
  "brand_nba",
  "brand_mlb",
  "brand_william-hill_color",
  "brand_nba_conference_western_color",
  "brand_nba_conference_eastern_color",
  "brand_caesars-sportsbook-color",
  "brand_caesars-sportsbook-casino-color",
  "brand_czr-goblet-app_color",
  "brand_wsop_color"
];  
      