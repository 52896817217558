import React from "react";
import { DetailProps } from "../types";
import cx from "components/src/utils/cx";
import { tailwindSemanticColors as twFg } from "components/src/shared/styles/colorStyles";
import { IconProps } from "components/src/components/Icon/types";
import { Icon } from "../../Icon/Icon";
import { makeIcon } from "../js/makeIcon";
import { Divider } from "./Divider";

export const Detail = ({
  tag,
  fgColor = "fg-moderate",
  textSize = "body-sm-bold",
  bgColor = "neutrals-transparent",
  leftIcon = "",
  rightIcon = "",
  showDivider = true,
  isFirst = false,
  isLast = false
}: DetailProps) => {
  let _leftIcon: IconProps | null = makeIcon(leftIcon) as IconProps;
  let _rightIcon: IconProps | null = makeIcon(rightIcon) as IconProps;
  const isClearOrDefaultBg = bgColor === "bg-default" || bgColor === "neutrals-transparent"
  const isBothFirstAndLast = isFirst && isLast;
  return (
    <>
        {showDivider && !isFirst ? <Divider /> : null}
        <span
          className={cx(
            {
              [textSize]: !!textSize,
              "cui-mx-1": !!bgColor && !isClearOrDefaultBg,
              "cui-px-1": !isFirst && !isLast,
              "cui-pl-1": isLast && !isBothFirstAndLast,
              "cui-pr-1": isFirst && !isBothFirstAndLast
            },
            "cui-inline-flex cui-gap-1 cui-items-center cui-py-0 cui-rounded-2px"
          )}
          style={{
            color: `var(--${fgColor})`,
            background: `var(--${bgColor})`,
          }}
        >
          {_leftIcon ? <Icon {..._leftIcon} /> : null}
          {tag}
          {_rightIcon ? <Icon {..._rightIcon} /> : null}
      </span>
    </>
  );
};
