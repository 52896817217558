export default function cx(
  dynamic: Record<string, boolean> | string[] | string,
  staticClasses: string = ""
): string | undefined {
  if (Array.isArray(dynamic))
    return dynamic.join(" ").concat(" ").concat(staticClasses).trim();

  if (typeof dynamic === "object") {
    return Object.entries(dynamic)
      .filter(([key, value]) => value)
      .map(([key, value]) => key)
      .join(" ")
      .concat(" ")
      .concat(staticClasses)
      .trim();
  } else if (typeof dynamic === "string") {
    return `${dynamic} ${staticClasses}`.trim();
  }
}
