import { useStaticQuery, graphql } from 'gatsby';

export const useComponents = () => {
  return useStaticQuery(graphql`
  query getComponents {
    allAirtableComponents {
      edges {
        node {
          data {
            Atomic_name
            Description
            Documentation
            Figma_Library
            Name
            Released_In
            Slug
            Type
            Web
            iOS
            Screenshots {
              localFiles {
                absolutePath
                relativePath
                url
                name
                publicURL
              }
            }
            Hero_Image {
              localFiles {
                absolutePath
                relativePath
                url
                name
                publicURL
              }
            }
          }
        }
      }
    }
  }
  
`);
};

export default useComponents;
