import React, { useMemo } from "react";

const headers = ["Name", "Type", "Default", "Description"];
const scalars = {
  string: "String",
  null: "null",
  number: "Number",
  boolean: "Boolean",
  function: "Function",
  object: "Object",
  array: "Array",
  date: "Date",
  map: "Map",
  set: "Set",
  true: "true",
  false: "false"
};

const TableCol = ({ children }) => (
  <div className="py-4 border-b-1 border-solid border-fg-minimal flex-col items-center">
    {children}
  </div>
);

const TableRow = ({ prop, metadata, isEvent = false }) => (
  <React.Fragment key={`args-table-row-${prop.toLowerCase()}`}>
    <TableCol>
      {prop}
      {metadata.type?.required ? <span className="text-red-600">*</span> : ""}
    </TableCol>
    <TableCol>
      {Array.isArray(metadata.propType) ? (
        metadata.propType.map((prop) => <Code className="mr-2" key={`${prop}-key`}>{prop}</Code>)
      ) : metadata.propType ? (
        <Code>{metadata.propType}</Code>
      ) : (
        <div></div>
      )}
    </TableCol>
    <TableCol>
      {metadata.default ? (
        <Code className="!text-size-3">{metadata.default}</Code>
      ) : (
        <div></div>
      )}
    </TableCol>
    <TableCol>
      <div className="flex-col">
        {isEvent ? <pre className="!text-size-4 text-highlight-accent">{metadata.signature}</pre> : null}
        {metadata.description ? <div>{metadata.description}</div> : null}
      </div>
    </TableCol>
  </React.Fragment>
);
const Code = ({ children, className }) => (
  <code
    className={
      "!text-size-3" +
      ` ${className} ` +
      `${children in scalars ? "!text-highlight-accent" : ""}`
    }
  >
    {children in scalars ? scalars[children] : children}
  </code>
);

export const ArgsTable = ({ className, component, data }) => {

  const dataArr = data ? Object.entries(data) : [];
  const isEvent = (data) =>
    data.propType === "function" || data.table?.category === "Events";
  const eventsArr = useMemo(()=> dataArr.filter(([_, data]) => isEvent(data)), [data]);
  const propsArr = useMemo(()=> dataArr.filter(([_, data]) => !isEvent(data)), [data]);
  
  if (!data) return <></>

  return (
    <div
      className={
        `grid grid-cols-[1fr,1fr,1fr,3fr] text-size-4 ${className}`
      }
    >
      {headers.map((header) => (
        <div
          key={`args-table-header-${header}`}
          className="text-size-6 font-bold border-b-1 border-solid border-fg-moderate pb-5"
        >
          {header}
        </div>
      ))}
      {propsArr.map(([prop, metadata]) => (
        <TableRow prop={prop} metadata={metadata} key={`${prop}-key`} />
      ))}
      {eventsArr.length ? <h3 className="col-span-4 pt-10 pb-4 border-solid border-b-1 border-fg-subtle">Events</h3> : null}
      {eventsArr ? eventsArr.map(([prop, metadata]) => (
        <TableRow prop={prop} metadata={metadata} isEvent={true} key={`${prop}-key`} />
      )) : null}
    </div>
  );
};

export default ArgsTable;
