import React from "react";
import { ButtonGroup } from "@caesars-digital/caesars-ui"
import { useDispatch, useSelector } from "react-redux";
import { setPlatform, platformSelector } from "~/state/globalSlice";

const items = [{
    itemId: 0,
    label: "Web (CSS)"
},{
    itemId: 1,
    label: "Tailwind"
},{
    itemId: 2,
    label: "iOS"
},{
    itemId: 3,
    label: "Android"
}]


const PlatformSwitcher = ({handleToggle, ...rest}) => {
    const dispatch = useDispatch();
    const platform = useSelector(platformSelector);
    const handleClick = (e, id)=>{ 
        const [selectedItem] = items.filter(i => i.itemId === id)
        dispatch(setPlatform(selectedItem.label))
    }
    const activeItem = items.filter(item => item.label === platform)[0].itemId;
    return <ButtonGroup items={items} initialItem={activeItem}
    handleClick={handleClick} {...rest} />
}

export default PlatformSwitcher;