import React from "react";
import { Link } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";

export const CzrLink = ({ href, children, className, ...rest }) => {
  const hasHttp = /^https?:\/\//gi.test(href);
  const hasAnchor = /(#.+)$/gi.test(href);
  const isImageLink = className === "gatsby-resp-image-link";
  if (isImageLink || hasHttp)
    return (
      <a href={href} className={className} {...rest}>
        {children}
      </a>
    );

  if (hasAnchor)
    return (
      <AnchorLink to={href} {...rest} stripHash>
        {children}
      </AnchorLink>
    );
  return (
    <Link to={href} className={className} {...rest}>
      {children}
    </Link>
  );
};

export default CzrLink;
