import React from "react";
import { StatusPill } from "~/components/StatusPill/StatusPill";

const headers = ["Principle", "Status", "Description"];

const TableRow = ({ children }) => (
  <div className="py-4 border-b-1 border-solid border-fg-minimal flex items-center">
    {children}
  </div>
);

const Principle = ({principle, status, children}) => (<>
  <TableRow>{principle}</TableRow>
  <TableRow><StatusPill status={status} /></TableRow>
  <TableRow>{children}</TableRow>
</>)

export const AccessibilityTable = ({ children, className }) => {
  return (
    <div className={`grid grid-cols-[1fr,1fr,3fr] text-size-4 ${className}`}>
      {headers.map((header) => (
        <div
          key={`args-table-header-${header}`}
          className="text-size-6 font-bold border-b-1 border-solid border-fg-moderate pb-5"
        >
          {header}
        </div>
      ))}
      {children}
    </div>
  );
};

AccessibilityTable.Principle = Principle;

export default AccessibilityTable;
