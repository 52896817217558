import React from "react";
import { Link } from "gatsby";

export const CButton = ({ children, link, className, ...rest }) => {
  return (
    <Link to={link}>
      <button className={`px-6 py-3 rounded-[40px] text-size-6 bg-bg-primary text-fg-inverse font-medium cursor-pointer ${className}`} {...rest}>
        {children}
      </button>
    </Link>
  );
};

export default CButton;
