

/*  |=====================================================|
    | Auto Generated by Caesars UI - DO NOT EDIT DIRECTLY |
    | https://caesars-ui.pages.dev                        |
    |=====================================================| */


export default {
  "display": {
    "3xl": "display-3xl",
    "3xl-bold": "display-3xl-bold",
    "2xl": "display-2xl",
    "2xl-bold": "display-2xl-bold",
    "xl": "display-xl",
    "xl-bold": "display-xl-bold",
    "lg": "display-lg",
    "lg-bold": "display-lg-bold",
    "md": "display-md",
    "md-bold": "display-md-bold",
    "sm": "display-sm",
    "sm-bold": "display-sm-bold",
    "xs": "display-xs",
    "xs-bold": "display-xs-bold"
  },
  "heading": {
    "2xl": "heading-2xl",
    "2xl-bold": "heading-2xl-bold",
    "xl": "heading-xl",
    "xl-bold": "heading-xl-bold",
    "lg": "heading-lg",
    "lg-bold": "heading-lg-bold",
    "md": "heading-md",
    "md-bold": "heading-md-bold",
    "sm": "heading-sm",
    "sm-bold": "heading-sm-bold",
    "xs": "heading-xs",
    "xs-bold": "heading-xs-bold"
  },
  "body": {
    "xl": "body-xl",
    "xl-bold": "body-xl-bold",
    "lg": "body-lg",
    "lg-bold": "body-lg-bold",
    "md": "body-md",
    "md-bold": "body-md-bold",
    "sm": "body-sm",
    "sm-bold": "body-sm-bold",
    "xs": "body-xs",
    "xs-bold": "body-xs-bold"
  }
};
