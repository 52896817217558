import React from "react";
import CButton from "~/components/CButton/CButton";
import { versionSelector } from "~/state/globalSlice";
import { useSelector } from "react-redux";

const LatestDownloadBar = () => {
  const version = useSelector(versionSelector);
  return (
    <div className="mb-6">
      <CButton
        onClick={(e) => window.open(`/dist/caesars-ui.${version}.zip`, "_self")}
        link=""
      >
        Download Caesars UI Zip
      </CButton>
      <CButton
        className="ml-4 !bg-bg-layer-one !text-fg-primary"
        onClick={(e) => window.open("/download", "_self")}
        link=""
      >
        Visit Download Page
      </CButton>
    </div>
  );
};

export default LatestDownloadBar;
