import { useStaticQuery, graphql } from 'gatsby';

export const useAllMdx = () => {
  return useStaticQuery(graphql`
  query getMDX {
    allMdx {
      edges {
        node {
          id
          slug
          frontmatter {
            menuLabel
            sortOrder
          }
        }
      }
    }
  }    
`);
};

export default useAllMdx;
