import { SizeType } from "components/src/shared/types"
export const col = {
    auto: "cui-col-auto",
    start: {
        1: "cui-col-start-1",
        2: "cui-col-start-2",
        3: "cui-col-start-3",
        4: "cui-col-start-4",
        5: "cui-col-start-5",
        6: "cui-col-start-6",
        7: "cui-col-start-7",
        8: "cui-col-start-8",
        9: "cui-col-start-9",
        10: "cui-col-start-10",
        11: "cui-col-start-11",
        12: "cui-col-start-12",
        auto: "cui-col-start-auto",
        halfway: "cui-col-start-[calc(calc(var(--cui-grid-cols)/2)+1)]"
    },
    end: {
        1: "cui-col-end-1",
        2: "cui-col-end-2",
        3: "cui-col-end-3",
        4: "cui-col-end-4",
        5: "cui-col-end-5",
        6: "cui-col-end-6",
        7: "cui-col-end-7",
        8: "cui-col-end-8",
        9: "cui-col-end-9",
        10: "cui-col-end-10",
        11: "cui-col-end-11",
        12: "cui-col-end-12",
        auto: "cui-col-end-auto",
        last: "cui-col-end-[-1]"
    },
    span: {
        1: "cui-col-span-1",
        2: "cui-col-span-2",
        3: "cui-col-span-3",
        4: "cui-col-span-4",
        5: "cui-col-span-5",
        6: "cui-col-span-6",
        7: "cui-col-span-7",
        8: "cui-col-span-8",
        9: "cui-col-span-9",
        10: "cui-col-span-10",
        11: "cui-col-span-11",
        12: "cui-col-span-12",
        full: "cui-col-span-full",
        auto: "cui-col-auto",
    }
}

export const row = {
    auto: "cui-row-auto",
    start: {
        1: "cui-row-start-1",
        2: "cui-row-start-2",
        3: "cui-row-start-3",
        4: "cui-row-start-4",
        5: "cui-row-start-5",
        6: "cui-row-start-6",
        7: "cui-row-start-7",
        8: "cui-row-start-8",
        9: "cui-row-start-9",
        10: "cui-row-start-10",
        11: "cui-row-start-11",
        12: "cui-row-start-12",
        auto: "cui-row-start-auto",
        halfway: "cui-row-start-[calc(calc(var(--cui-grid-cols)/2)+1)]"
    },
    end: {
        1: "cui-row-end-1",
        2: "cui-row-end-2",
        3: "cui-row-end-3",
        4: "cui-row-end-4",
        5: "cui-row-end-5",
        6: "cui-row-end-6",
        7: "cui-row-end-7",
        8: "cui-row-end-8",
        9: "cui-row-end-9",
        10: "cui-row-end-10",
        11: "cui-row-end-11",
        12: "cui-row-end-12",
        auto: "cui-row-end-auto",
        last: "cui-row-end-[-1]",
    },
    span: {
        1: "cui-row-span-1",
        2: "cui-row-span-2",
        3: "cui-row-span-3",
        4: "cui-row-span-4",
        5: "cui-row-span-5",
        6: "cui-row-span-6",
        7: "cui-row-span-7",
        8: "cui-row-span-8",
        9: "cui-row-span-9",
        10: "cui-row-span-10",
        11: "cui-row-span-11",
        12: "cui-row-span-12",
        full: "cui-row-span-full",
        auto: "cui-row-auto",
    }
}

export const templateCols = {
    1: "cui-grid-cols-1",
    2: "cui-grid-cols-2",
    // 3: "cui-grid-cols-3",
    4: "cui-grid-cols-4",
    // 5: "cui-grid-cols-5",
    6: "cui-grid-cols-6",
    // 7: "cui-grid-cols-7",
    8: "cui-grid-cols-8",
    // 9: "cui-grid-cols-9",
    10: "cui-grid-cols-10",
    // 11: "cui-grid-cols-11",
    12: "cui-grid-cols-12"
}

export const gapClasses: Record<any, any> = {
    "2px": "cui-gap-2px",
    "2xs": "cui-gap-2xs",
    xs: "cui-gap-xs",
    sm: "cui-gap-sm",
    md: "cui-gap-md", 
    lg: "cui-gap-lg",
    none: "cui-gap-none"
}