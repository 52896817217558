import React, { useState } from "react";
import cx from "~/utils/cx";
import { Link } from "gatsby";
import Menu from "../Menu";
import Chevron from "~/components/Chevron/Chevron";

const handleProps = ({ isPartiallyCurrent, isCurrent }, setActive) => {
  setTimeout(() => {
    setActive(isPartiallyCurrent);
  }, 50);
  return (
    isPartiallyCurrent && {
      className: "flex justify-between text-highlight-brand font-bold",
    }
  );
};

const level = {
  1: {
    inactive: "text-size-8 py-3",
    active: "font-bold",
    shared:
      "",
  },
  2: {
    inactive:
      "ml-4 px-4 py-2 border-l border-fg-bold after:border-l-2 after:border-fg-bold after:scale-y-0",
    active: "after:border-l-2 after:border-b-1g-primary after:scale-y-100",
    shared: "text-size-6",
  },
  3: {
    inactive:
      "text-[18px] ml-4 py-1 after:border-l-2 after:scale-y-0 after:-translate-x-[32px]",
    active:
      "text-[18px] after:border-l-2 after:border-b-1g-primary  after:scale-y-100",
    shared: "",
  },
};

export const MenuItem = ({
  id,
  slug,
  isFolder,
  submenu,
  hasSubmenu,
  frontmatter: { menuLabel: label },
}) => {
  const [isActive, setActive] = useState(false);
  const levelDepth = slug.replace(/\/$/, "").split("/").length;
  const levelState = {
    1: {
      active: isActive && !hasSubmenu,
      inactive: !hasSubmenu,
    },
    2: {
      active: isActive && hasSubmenu && levelDepth === 2,
      inactive: hasSubmenu && levelDepth === 2,
    },
    3: {
      active: isActive && hasSubmenu && levelDepth === 3,
      inactive: hasSubmenu && levelDepth === 3,
    },
  };
  return (
    <li
      key={id}
      className={cx(
        {
          [level[levelDepth].inactive]: levelState[levelDepth].inactive,
          [level[levelDepth].active]: levelState[levelDepth].active,
          [level[levelDepth].shared]: true,
        },
        "font-medium text-fg-default hover:text-highlight-brand relative after:max-h-[44px] after:absolute after:transition-all after:origin-top after:duration-200 after:inset-0 after:pointer-events-none"
      )}
    >
      <Link
        to={"/" + slug}
        className="flex justify-between"
        getProps={(props) => handleProps(props, setActive)}
      >
        {label} {isFolder && <Chevron isOpen={isActive} />}
      </Link>
      {submenu?.length ? (
        <Menu data={submenu} hasSubmenu={true} isOpen={isActive} />
      ) : null}
    </li>
  );
};

export default MenuItem;
