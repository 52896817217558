import React from "react";
import { CardProps } from "../types";
import { CardHead } from "./CardHead";
import { CardSection } from './CardSection';

export const CardHeader = ({ children, noPad, ...rest }: Partial<CardProps>) => {
  return children ? (
    <CardSection x={!noPad ? "cui-px-md" : ""} {...rest}>
      {children}
    </CardSection>
  ) : (
    <CardHead {...rest} />
  );
};

CardHeader.displayName = "CardHeader";