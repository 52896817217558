import React from "react";
import * as Select from "@radix-ui/react-select";
import isObject from "lodash/isObject";
import "./SelectMenu.css";
import { useSelector } from 'react-redux';
import { themeSelector } from "~/state/globalSlice";

import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@radix-ui/react-icons";

const SelectMenu = ({ items, placeholder, label, onValueChange, defaultValue }) => {
  const theme = useSelector(themeSelector);
  return (
    <div className="flex flex-col">
      <label className="text-size-5 font-bold">{label}</label>
      <Select.Root onValueChange={onValueChange} defaultValue={defaultValue} className="SelectRoot">
        <Select.Trigger
          className="SelectTrigger"
          // className="min-w-[200px] bg-bg-default px-3 py-2 text-size-5 inline-flex justify-between items-center border-1 border-solid border-fg-subtle rounded-1"
          aria-label={label}
        >
          <Select.Value placeholder={placeholder} />
          <Select.Icon 
          // className="text-fg-moderate" 
          className="SelectIcon">
            <ChevronDownIcon />
          </Select.Icon>
        </Select.Trigger>
        <Select.Portal className={`z-50 ${theme}`}>
          <Select.Content 
          className="SelectContent"
          // className="relative overflow-hidden bg-bg-default rounded-2 shadow-4"
          >
            <Select.ScrollUpButton className="SelectScrollButton">
              <ChevronUpIcon />
            </Select.ScrollUpButton>
            <Select.Viewport
              className="SelectViewport"
            >
                {Object.entries(items).map(([key, val]) => {
                  if (isObject(val)) {
                    return (
                      <Select.Group key={`item-${key}-group`}>
                        <Select.Label className="SelectLabel">
                          {key}
                        </Select.Label>
                        {Object.entries(val).map(([key2, val2]) => (
                          <SelectItem value={val2} key={`item-${key2}`}>{key2}</SelectItem>
                        ))}
                      </Select.Group>
                    );
                  } else {
                    return <SelectItem value={val} key={`item-${key}`}>{key}</SelectItem>;
                  }
                })}
            </Select.Viewport>
            <Select.ScrollDownButton  className="SelectScrollButton">
              <ChevronDownIcon />
            </Select.ScrollDownButton>
          </Select.Content>
        </Select.Portal>
      </Select.Root>
    </div>
  );
};

const SelectItem = React.forwardRef(
  ({ children, className, ...props }, forwardedRef) => {
    return (
      <Select.Item
        className="SelectItem"
        {...props}
        ref={forwardedRef}
      >
        <Select.ItemText>{children}</Select.ItemText>
        <Select.ItemIndicator className="SelectItemIndicator">
          <CheckIcon />
        </Select.ItemIndicator>
      </Select.Item>
    );
  }
);

export default SelectMenu;
