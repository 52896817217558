export default function cx(dynamic, staticClasses) {
    if (staticClasses === void 0) { staticClasses = ""; }
    if (Array.isArray(dynamic))
        return dynamic.filter(function (c) { return !!c; }).join(" ").concat(" ").concat(staticClasses);
    if (typeof dynamic === "object") {
        return Object.entries(dynamic)
            .filter(function (_a) {
            var key = _a[0], value = _a[1];
            return value;
        })
            .map(function (_a) {
            var key = _a[0], value = _a[1];
            return key;
        })
            .join(" ")
            .concat(" ")
            .concat(staticClasses);
    }
    else if (typeof dynamic === "string") {
        return "".concat(dynamic, " ").concat(staticClasses);
    }
}
