import React from "react";
import { SpanProps } from "react-html-props";
import { LoadedIconProps } from "../types";
import styles from "./css/LiveIcon.module.scss";

// fill, secondaryfill, width, height
// !! remove any
export const LiveIcon = ({fill, secondaryfill, width, height, className, accessibilityLabel, ...rest}: SpanProps & LoadedIconProps & any) => {
    let _width = typeof width === 'string' ? +width : width;
    let _height: string | number = height === "100%" ? _width : 0;
    const iconSize = { width: _width, height: _height };
    return <span className={`cui-relative cui-inline-block ${className ? className : "" }`.trim()} {...rest}
    style={iconSize}>
        <span className="cui-absolute cui-top-0 cui-block cui-rounded-[9999px] cui-bg-highlight-accent" style={iconSize} />
        {/* <span className={`cui-absolute cui-top-0 cui-block cui-rounded-[9999px] cui-bg-highlight-accent ${styles['animate-ping-slow']}`} style={iconSize} /> */}
    </span>
}