import React from "react";
import typographyClasses from 'tokens/build/js/semantic-typography';
import { Body, Heading, Display } from "@caesars-digital/caesars-ui"

const typography = {
    body: Body,
    heading: Heading,
    display: Display
}

export const TypographyExamples = ({ component }) => {
    const _component = component.toLowerCase();
    const CurrentComponent = typography[_component];
    return <table className="cui-w-full mb-20">
    <tbody>
    <tr className="border-b-1 border-solid border-fg-moderate">
      <th className="text-left font-bold text-size-6 px-4 pt-2 pb-5">Size</th>
      <th className="text-left font-bold text-size-6 px-4 pt-2 pb-5">Example</th>
    </tr>
    {Object.entries(typographyClasses[_component]).map(([name, className]) => (
      <tr key={`${name}-row`}>
        <td className="cui-text-size-4 cui-text-fg-moderate cui-font-bold py-2 px-4">{name}</td>
        <td className="px-4 py-2">{<CurrentComponent size={name} tag="span">Be a Caesar!</CurrentComponent>}</td>
      </tr>
    ))}
    </tbody>
  </table>
}

export default TypographyExamples;
