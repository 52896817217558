import React from "react";

export const ClipBoardIcon = ({ color, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    className={`m-0 p-0 w-5 h-5 ml-2 scale-75 hover:scale-100 peer-hover:scale-100 cursor-pointer transition-scale duration-100 ${color}`}
    {...rest}
  >
    <g fill="current" className="nc-icon-wrapper">
      <path
        d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"
        fill="current"
      ></path>
    </g>
  </svg>
);

export const StorybookIcon = () => (<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_765_23450)">
<mask id="mask0_765_23450" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="15" y="14" width="14" height="17">
<path d="M15.8339 28.9127L15.3341 15.5544C15.3176 15.1132 15.6548 14.7393 16.0941 14.7118L27.4216 14.0016C27.8687 13.9736 28.2538 14.3144 28.2818 14.7629C28.2828 14.7798 28.2834 14.7968 28.2834 14.8137V29.37C28.2834 29.8194 27.9202 30.1837 27.4722 30.1837C27.4601 30.1837 27.4479 30.1834 27.4358 30.1828L16.6081 29.695C16.1864 29.676 15.8497 29.3358 15.8339 28.9127Z" fill="white"/>
</mask>
<g mask="url(#mask0_765_23450)">
<path d="M15.8339 28.7435L15.3341 15.5367C15.3176 15.1005 15.6548 14.7309 16.0941 14.7037L27.4216 14.0016C27.8687 13.9739 28.2538 14.3109 28.2818 14.7543C28.2828 14.771 28.2834 14.7877 28.2834 14.8045V29.1956C28.2834 29.6398 27.9202 30 27.4722 30C27.4601 30 27.4479 29.9997 27.4358 29.9992L16.6081 29.5169C16.1864 29.4981 15.8497 29.1618 15.8339 28.7435Z" fill="currentColor"/>
<path d="M24.896 15.9892L24.9734 14.1226L26.529 14L26.596 15.925C26.5983 15.992 26.5461 16.0482 26.4793 16.0505C26.4507 16.0515 26.4227 16.0423 26.4002 16.0246L25.8003 15.5506L25.09 16.091C25.0368 16.1315 24.9609 16.121 24.9205 16.0676C24.9035 16.0451 24.8948 16.0174 24.896 15.9892ZM22.9065 20.0998C22.9065 20.4164 25.0324 20.2647 25.3177 20.0423C25.3177 17.8866 24.1646 16.7538 22.0529 16.7538C19.9412 16.7538 18.7581 17.9042 18.7581 19.6299C18.7581 22.6354 22.8017 22.6929 22.8017 24.3322C22.8017 24.7924 22.5771 25.0656 22.0828 25.0656C21.4389 25.0656 21.1843 24.7358 21.2142 23.6141C21.2142 23.3707 18.7581 23.2949 18.6832 23.6141C18.4925 26.3323 20.1808 27.1163 22.1128 27.1163C23.9848 27.1163 25.4525 26.1154 25.4525 24.3035C25.4525 21.0823 21.349 21.1686 21.349 19.5723C21.349 18.9252 21.8282 18.8389 22.1128 18.8389C22.4123 18.8389 22.9515 18.8919 22.9065 20.0998Z" fill="var(--fg-inverse)"/>
</g>
</g>
<defs>
<clipPath id="clip0_765_23450">
<rect width="16" height="16" fill="currentColor" transform="translate(14 14)"/>
</clipPath>
</defs>
</svg>
)

export const StickerSheetIcon = () => (<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_765_23516)">
<path d="M29.3688 20.631C29.1204 19.287 28.5095 18.0363 27.6019 17.0142C26.6944 15.9921 25.5249 15.2375 24.2196 14.8318C22.9144 14.4262 21.5231 14.3849 20.1961 14.7125C18.8691 15.0401 17.6568 15.7241 16.6904 16.6906C15.7239 17.6571 15.0399 18.8693 14.7123 20.1963C14.3847 21.5233 14.4259 22.9146 14.8316 24.2199C15.2373 25.5251 15.9919 26.6947 17.014 27.6022C18.0361 28.5097 19.2867 29.1207 20.6308 29.369L29.3688 20.631Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M29.3687 20.6309C28.1718 20.4037 26.9376 20.4725 25.7734 20.8312C24.6092 21.1899 23.5503 21.8277 22.6889 22.6891C21.8275 23.5505 21.1897 24.6094 20.8309 25.7736C20.4722 26.9378 20.4035 28.1721 20.6307 29.3689" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
</g>
<defs>
<clipPath id="clip0_765_23516">
<rect width="16" height="16" fill="currentColor" transform="translate(14 14)"/>
</clipPath>
</defs>
</svg>
)

export const CodeIcon = () => (<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M24.5 15.5L19.5 28.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M17.5 18.5L14.5 21.5L17.5 24.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M26.5 18.5L29.5 21.5L26.5 24.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
)

export const CopyIcon = () => (<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_765_23453)">
<path d="M25.5 14.5H14.5V25.5H25.5V14.5Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M27.5 18.5H29.5V29.5H18.5V27.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
</g>
<defs>
<clipPath id="clip0_765_23453">
<rect width="16" height="16" fill="currentColor" transform="translate(14 14)"/>
</clipPath>
</defs>
</svg>
)