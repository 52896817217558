import { useStaticQuery, graphql } from 'gatsby';

export const useAllTokens = () => {
  return useStaticQuery(graphql`
  query getTokens {
    allAirtableTokensColors {
      edges {
        node {
          id
          data {
            sortOrder
            Name,
          }
          recordId
        }
      }
    }
    allAirtableTokensSpacing {
      edges {
        node {
          data {
            Name,
            Figma_usage,
            Current_value,
            Type,
            SortOrder
          }
        }
      }
    }
    allAirtableTokensBorderWidth {
      edges {
        node {
          data {
            Name,
            Figma_usage,
            Current_value,
            Type,
            SortOrder
          }
        }
      }
    }
    allAirtableTokensBorderRadius {
      edges {
        node {
          data {
            Name,
            Figma_usage,
            Current_value,
            Type,
            SortOrder
          }
        }
      }
    }
    allAirtableTokensTypography {
      edges {
        node {
          data {
            Name
            Figma_usage
            Current_value
            SortOrder
            Type
          }
        }
      }
    }
    allAirtableTokensShadow {
      edges {
        node {
          data {
            Name,
            Figma_usage,
            Current_value,
            Type,
            SortOrder
          }
        }
      }
    }
  }
`);
};

export default useAllTokens;
