import React from "react";
import useComponents from "~/hooks/useAllComponents";

export const ComponentDescription = ({ of = "" }) => {
  const component =
    useComponents()
      .allAirtableComponents.edges.map((e) => e.node.data)
      .filter(
        (d) =>
          d.Type === "Component" && d.Name.toLowerCase().replace(/ /g, '') === of.toLowerCase()
      )[0] || null;
  if (!component) return null;

  return (
   <p className="p-large">{component.Description}</p>
  );
};

export default ComponentDescription;
