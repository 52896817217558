import React, { createContext, useContext } from "react";
import useAllMdx from "~/hooks/useAllMdx";
import useVersion from "~/hooks/useVersion";

const RootQueryContext = createContext();

export const RootQueryProvider = ({ children }) => {
    const menuData = useAllMdx();
    const version = useVersion();
    return <RootQueryContext.Provider value={{menuData, version}}>{children}</RootQueryContext.Provider>
};

export const useRootQuery = () => {
    return useContext(RootQueryContext)
}

export default RootQueryProvider;