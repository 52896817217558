/**
 * Do not edit directly
 * Generated on Fri, 15 Nov 2024 20:59:20 GMT
 */

module.exports = {
  "horseshoe-dark": {
    "fg": {
      "default": {
        "default": {
          "value": "#f6f2ed",
          "type": "color",
          "description": "Default color for text, icons and borders.",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.gray-dark.1200}",
            "type": "color",
            "description": "Default color for text, icons and borders.",
            "attributes": {
              "category": "color"
            }
          },
          "name": "fg-default",
          "path": [
            "horseshoe-dark",
            "fg",
            "default",
            "default"
          ]
        },
        "moderate": {
          "value": "#aeaaa6",
          "type": "color",
          "description": "Content that is secondary or that provides additional context.",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.gray-dark.900}",
            "type": "color",
            "description": "Content that is secondary or that provides additional context.",
            "attributes": {
              "category": "color"
            }
          },
          "name": "fg-moderate",
          "path": [
            "horseshoe-dark",
            "fg",
            "default",
            "moderate"
          ]
        },
        "subtle": {
          "value": "#686663",
          "type": "color",
          "description": "Dividers or other elements not critical to understanding the flow of an interface.",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.gray-dark.700}",
            "type": "color",
            "description": "Dividers or other elements not critical to understanding the flow of an interface.",
            "attributes": {
              "category": "color"
            }
          },
          "name": "fg-subtle",
          "path": [
            "horseshoe-dark",
            "fg",
            "default",
            "subtle"
          ]
        },
        "minimal": {
          "value": "#383736",
          "type": "color",
          "description": "Dividers or other elements not critical to understanding the flow of an interface.",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.gray-dark.600}",
            "type": "color",
            "description": "Dividers or other elements not critical to understanding the flow of an interface.",
            "attributes": {
              "category": "color"
            }
          },
          "name": "fg-minimal",
          "path": [
            "horseshoe-dark",
            "fg",
            "default",
            "minimal"
          ]
        },
        "inverse": {
          "default": {
            "value": "#263456",
            "type": "color",
            "description": "Use on bg.primary.",
            "attributes": {
              "category": "color"
            },
            "filePath": "tokens/tokens.json",
            "isSource": true,
            "original": {
              "value": "{core.brand.horseshoe-midnight}",
              "type": "color",
              "description": "Use on bg.primary.",
              "attributes": {
                "category": "color"
              }
            },
            "name": "fg-inverse",
            "path": [
              "horseshoe-dark",
              "fg",
              "default",
              "inverse",
              "default"
            ]
          }
        }
      },
      "primary": {
        "default": {
          "value": "#fff1d6",
          "type": "color",
          "description": "The most important elements in the interface in which a user must take action on.",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.cream-dark.1200}",
            "type": "color",
            "description": "The most important elements in the interface in which a user must take action on.",
            "attributes": {
              "category": "color"
            }
          },
          "name": "fg-primary",
          "path": [
            "horseshoe-dark",
            "fg",
            "primary",
            "default"
          ]
        },
        "strong": {
          "value": "#fff1d6",
          "type": "color",
          "description": "Selected text, icons and borders.",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.cream-dark.1200}",
            "type": "color",
            "description": "Selected text, icons and borders.",
            "attributes": {
              "category": "color"
            }
          },
          "name": "fg-primary-strong",
          "path": [
            "horseshoe-dark",
            "fg",
            "primary",
            "strong"
          ]
        },
        "minimal": {
          "value": "#293658",
          "type": "color",
          "description": "For content that is secondary or that provides additional context. Does not pass contrast standards.",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.navy-dark.500}",
            "type": "color",
            "description": "For content that is secondary or that provides additional context. Does not pass contrast standards.",
            "attributes": {
              "category": "color"
            }
          },
          "name": "fg-primary-minimal",
          "path": [
            "horseshoe-dark",
            "fg",
            "primary",
            "minimal"
          ]
        }
      },
      "global-black": {
        "value": "#000000",
        "type": "color",
        "description": "When UI element will always be black regardless of theme.",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "{core.neutrals.black}",
          "type": "color",
          "description": "When UI element will always be black regardless of theme.",
          "attributes": {
            "category": "color"
          }
        },
        "name": "fg-global-black",
        "path": [
          "horseshoe-dark",
          "fg",
          "global-black"
        ]
      },
      "global-white": {
        "value": "#ffffff",
        "type": "color",
        "description": "When UI element will always be white regardless of theme.",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "{core.neutrals.white}",
          "type": "color",
          "description": "When UI element will always be white regardless of theme.",
          "attributes": {
            "category": "color"
          }
        },
        "name": "fg-global-white",
        "path": [
          "horseshoe-dark",
          "fg",
          "global-white"
        ]
      },
      "focus": {
        "default": {
          "value": "#259CFF",
          "type": "color",
          "description": "The color used to indicate focus on an interactive element, such as a button or input field.",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.blue-dark.800}",
            "type": "color",
            "description": "The color used to indicate focus on an interactive element, such as a button or input field.",
            "attributes": {
              "category": "color"
            }
          },
          "name": "fg-focus",
          "path": [
            "horseshoe-dark",
            "fg",
            "focus",
            "default"
          ]
        },
        "inverse": {
          "value": "#1B3665",
          "type": "color",
          "description": "The inverse color of the focus color, used to indicate focus on elements with a dark background. This color should provide enough contrast with the dark background to be easily visible.",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.blue-dark.500}",
            "type": "color",
            "description": "The inverse color of the focus color, used to indicate focus on elements with a dark background. This color should provide enough contrast with the dark background to be easily visible.",
            "attributes": {
              "category": "color"
            }
          },
          "name": "fg-focus-inverse",
          "path": [
            "horseshoe-dark",
            "fg",
            "focus",
            "inverse"
          ]
        }
      },
      "text-link": {
        "default": {
          "value": "#348FB2",
          "type": "color",
          "description": "For hyperlinks. This passes contrast standards on the background and surrounding text.",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.aqua-dark.700}",
            "type": "color",
            "description": "For hyperlinks. This passes contrast standards on the background and surrounding text.",
            "attributes": {
              "category": "color"
            }
          },
          "name": "fg-text-link",
          "path": [
            "horseshoe-dark",
            "fg",
            "text-link",
            "default"
          ]
        }
      }
    },
    "bg": {
      "default": {
        "base": {
          "value": "#15213D",
          "type": "color",
          "description": "Provides visual rest and contrast against the default background.",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.rogue.midnight-dark.100}",
            "type": "color",
            "description": "Provides visual rest and contrast against the default background.",
            "attributes": {
              "category": "color"
            }
          },
          "name": "bg-base",
          "path": [
            "horseshoe-dark",
            "bg",
            "default",
            "base"
          ]
        },
        "default": {
          "value": "#15213D",
          "type": "color",
          "description": "Default background color.",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.rogue.midnight-dark.100}",
            "type": "color",
            "description": "Default background color.",
            "attributes": {
              "category": "color"
            }
          },
          "name": "bg-default",
          "path": [
            "horseshoe-dark",
            "bg",
            "default",
            "default"
          ]
        },
        "layer-one": {
          "value": "#192747",
          "type": "color",
          "description": "For elements that lay on top of bg.default and lower layers.",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.rogue.midnight-dark.200}",
            "type": "color",
            "description": "For elements that lay on top of bg.default and lower layers.",
            "attributes": {
              "category": "color"
            }
          },
          "name": "bg-layer-one",
          "path": [
            "horseshoe-dark",
            "bg",
            "default",
            "layer-one"
          ]
        },
        "layer-two": {
          "value": "#1C2C50",
          "type": "color",
          "description": "For elements that lay on top of bg.default and lower layers.",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.rogue.midnight-dark.300}",
            "type": "color",
            "description": "For elements that lay on top of bg.default and lower layers.",
            "attributes": {
              "category": "color"
            }
          },
          "name": "bg-layer-two",
          "path": [
            "horseshoe-dark",
            "bg",
            "default",
            "layer-two"
          ]
        },
        "layer-three": {
          "value": "#263456",
          "type": "color",
          "description": "For elements that are highlighted on lower bg layers. Check contrast when using.",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.brand.horseshoe-midnight}",
            "type": "color",
            "description": "For elements that are highlighted on lower bg layers. Check contrast when using.",
            "attributes": {
              "category": "color"
            }
          },
          "name": "bg-layer-three",
          "path": [
            "horseshoe-dark",
            "bg",
            "default",
            "layer-three"
          ]
        }
      },
      "primary": {
        "default": {
          "value": "#fff1d6",
          "type": "color",
          "description": "The most important elements in the interface in which a user must take action on.",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.cream-dark.1200}",
            "type": "color",
            "description": "The most important elements in the interface in which a user must take action on.",
            "attributes": {
              "category": "color"
            }
          },
          "name": "bg-primary",
          "path": [
            "horseshoe-dark",
            "bg",
            "primary",
            "default"
          ]
        },
        "strong": {
          "value": "#fff1d6",
          "type": "color",
          "description": "A focal point of content.",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.cream-dark.1200}",
            "type": "color",
            "description": "A focal point of content.",
            "attributes": {
              "category": "color"
            }
          },
          "name": "bg-primary-strong",
          "path": [
            "horseshoe-dark",
            "bg",
            "primary",
            "strong"
          ]
        },
        "minimal": {
          "value": "#1b294a",
          "type": "color",
          "description": "Content that is secondary or that provides additional context. Needs a border to pass contrast standards.",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.navy-dark.400}",
            "type": "color",
            "description": "Content that is secondary or that provides additional context. Needs a border to pass contrast standards.",
            "attributes": {
              "category": "color"
            }
          },
          "name": "bg-primary-minimal",
          "path": [
            "horseshoe-dark",
            "bg",
            "primary",
            "minimal"
          ]
        }
      },
      "transparent": {
        "default": {
          "value": "#030303b3",
          "type": "color",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "#030303b3",
            "type": "color",
            "attributes": {
              "category": "color"
            }
          },
          "name": "bg-transparent",
          "path": [
            "horseshoe-dark",
            "bg",
            "transparent",
            "default"
          ]
        }
      }
    },
    "highlight": {
      "accent": {
        "default": {
          "value": "#9963FF",
          "type": "color",
          "description": "Indicate changing elements like live scores.",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.ultramarine-dark.700}",
            "type": "color",
            "description": "Indicate changing elements like live scores.",
            "attributes": {
              "category": "color"
            }
          },
          "name": "highlight-accent",
          "path": [
            "horseshoe-dark",
            "highlight",
            "accent",
            "default"
          ]
        },
        "strong": {
          "value": "#6F35FF",
          "type": "color",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.ultramarine-dark.600}",
            "type": "color",
            "attributes": {
              "category": "color"
            }
          },
          "name": "highlight-accent-strong",
          "path": [
            "horseshoe-dark",
            "highlight",
            "accent",
            "strong"
          ]
        },
        "subtle": {
          "value": "#311869",
          "type": "color",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.ultramarine-dark.400}",
            "type": "color",
            "attributes": {
              "category": "color"
            }
          },
          "name": "highlight-accent-subtle",
          "path": [
            "horseshoe-dark",
            "highlight",
            "accent",
            "subtle"
          ]
        },
        "minimal": {
          "value": "#1F1238",
          "type": "color",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.ultramarine-dark.300}",
            "type": "color",
            "attributes": {
              "category": "color"
            }
          },
          "name": "highlight-accent-minimal",
          "path": [
            "horseshoe-dark",
            "highlight",
            "accent",
            "minimal"
          ]
        }
      },
      "brand": {
        "default": {
          "value": "#FFF2D9",
          "type": "color",
          "description": "Indicate elements that have a brand flair.",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.brand.horseshoe-cream}",
            "type": "color",
            "description": "Indicate elements that have a brand flair.",
            "attributes": {
              "category": "color"
            }
          },
          "name": "highlight-brand",
          "path": [
            "horseshoe-dark",
            "highlight",
            "brand",
            "default"
          ]
        }
      },
      "one": {
        "default": {
          "value": "linear-gradient(180deg, #fffdf2 0%, #FDFAA3 16.5%, #FCE367 42%, #E2AC43 94%, #fdf18b 98%);",
          "type": "color",
          "description": "Highlight a non-important UI element. May not pass contrast standards for foreground.",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.gradient.yellow-dark}",
            "type": "color",
            "description": "Highlight a non-important UI element. May not pass contrast standards for foreground.",
            "attributes": {
              "category": "color"
            }
          },
          "name": "highlight-one",
          "path": [
            "horseshoe-dark",
            "highlight",
            "one",
            "default"
          ]
        }
      },
      "two": {
        "default": {
          "value": "#CD1007",
          "type": "color",
          "description": "Highlight a non-important UI element. May not pass contrast standards for foreground.",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.red-dark.600}",
            "type": "color",
            "description": "Highlight a non-important UI element. May not pass contrast standards for foreground.",
            "attributes": {
              "category": "color"
            }
          },
          "name": "highlight-two",
          "path": [
            "horseshoe-dark",
            "highlight",
            "two",
            "default"
          ]
        }
      },
      "three": {
        "default": {
          "value": "#a900d4",
          "type": "color",
          "description": "Highlight a non-important UI element. May not pass contrast standards for foreground.",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.purple-dark.600}",
            "type": "color",
            "description": "Highlight a non-important UI element. May not pass contrast standards for foreground.",
            "attributes": {
              "category": "color"
            }
          },
          "name": "highlight-three",
          "path": [
            "horseshoe-dark",
            "highlight",
            "three",
            "default"
          ]
        }
      },
      "four": {
        "default": {
          "value": "#0982FC",
          "type": "color",
          "description": "Highlight a non-important UI element. May not pass contrast standards for foreground.",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.blue-dark.700}",
            "type": "color",
            "description": "Highlight a non-important UI element. May not pass contrast standards for foreground.",
            "attributes": {
              "category": "color"
            }
          },
          "name": "highlight-four",
          "path": [
            "horseshoe-dark",
            "highlight",
            "four",
            "default"
          ]
        }
      }
    },
    "status": {
      "positive": {
        "default": {
          "value": "#00ac5f",
          "type": "color",
          "description": "A positive message, like a placing or winning a bet.",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.green-dark.800}",
            "type": "color",
            "description": "A positive message, like a placing or winning a bet.",
            "attributes": {
              "category": "color"
            }
          },
          "name": "status-positive",
          "path": [
            "horseshoe-dark",
            "status",
            "positive",
            "default"
          ]
        },
        "strong": {
          "value": "#00C46E",
          "type": "color",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.green-dark.900}",
            "type": "color",
            "attributes": {
              "category": "color"
            }
          },
          "name": "status-positive-strong",
          "path": [
            "horseshoe-dark",
            "status",
            "positive",
            "strong"
          ]
        },
        "subtle": {
          "value": "#007642",
          "type": "color",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.green-dark.600}",
            "type": "color",
            "attributes": {
              "category": "color"
            }
          },
          "name": "status-positive-subtle",
          "path": [
            "horseshoe-dark",
            "status",
            "positive",
            "subtle"
          ]
        },
        "minimal": {
          "value": "#0A140D",
          "type": "color",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.green-dark.200}",
            "type": "color",
            "attributes": {
              "category": "color"
            }
          },
          "name": "status-positive-minimal",
          "path": [
            "horseshoe-dark",
            "status",
            "positive",
            "minimal"
          ]
        }
      },
      "negative": {
        "default": {
          "value": "#F9315B",
          "type": "color",
          "description": "A negative message, like an error, blocking status or a loss of a bet.",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.coral-dark.700}",
            "type": "color",
            "description": "A negative message, like an error, blocking status or a loss of a bet.",
            "attributes": {
              "category": "color"
            }
          },
          "name": "status-negative",
          "path": [
            "horseshoe-dark",
            "status",
            "negative",
            "default"
          ]
        },
        "strong": {
          "value": "#ff5e72",
          "type": "color",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.coral-dark.800}",
            "type": "color",
            "attributes": {
              "category": "color"
            }
          },
          "name": "status-negative-strong",
          "path": [
            "horseshoe-dark",
            "status",
            "negative",
            "strong"
          ]
        },
        "subtle": {
          "value": "#C81844",
          "type": "color",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.coral-dark.600}",
            "type": "color",
            "attributes": {
              "category": "color"
            }
          },
          "name": "status-negative-subtle",
          "path": [
            "horseshoe-dark",
            "status",
            "negative",
            "subtle"
          ]
        },
        "minimal": {
          "value": "#1F0D0D",
          "type": "color",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.coral-dark.200}",
            "type": "color",
            "attributes": {
              "category": "color"
            }
          },
          "name": "status-negative-minimal",
          "path": [
            "horseshoe-dark",
            "status",
            "negative",
            "minimal"
          ]
        }
      },
      "alert": {
        "default": {
          "value": "#9ACEDC",
          "type": "color",
          "description": "A standard alert message, indicating a noteworthy event or condition that requires user attention, such as important updates or reminders.",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.turquoise-dark.1000}",
            "type": "color",
            "description": "A standard alert message, indicating a noteworthy event or condition that requires user attention, such as important updates or reminders.",
            "attributes": {
              "category": "color"
            }
          },
          "name": "status-alert",
          "path": [
            "horseshoe-dark",
            "status",
            "alert",
            "default"
          ]
        },
        "strong": {
          "value": "#D8EBF1",
          "type": "color",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.turquoise-dark.1100}",
            "type": "color",
            "attributes": {
              "category": "color"
            }
          },
          "name": "status-alert-strong",
          "path": [
            "horseshoe-dark",
            "status",
            "alert",
            "strong"
          ]
        },
        "subtle": {
          "value": "#007084",
          "type": "color",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.turquoise-dark.600}",
            "type": "color",
            "attributes": {
              "category": "color"
            }
          },
          "name": "status-alert-subtle",
          "path": [
            "horseshoe-dark",
            "status",
            "alert",
            "subtle"
          ]
        },
        "minimal": {
          "value": "#0A1315",
          "type": "color",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.turquoise-dark.200}",
            "type": "color",
            "attributes": {
              "category": "color"
            }
          },
          "name": "status-alert-minimal",
          "path": [
            "horseshoe-dark",
            "status",
            "alert",
            "minimal"
          ]
        }
      },
      "notification": {
        "value": "#F9315B",
        "type": "color",
        "description": "Use when communicating a non-critical notification in the application interface.",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "{core.coral-dark.700}",
          "type": "color",
          "description": "Use when communicating a non-critical notification in the application interface.",
          "attributes": {
            "category": "color"
          }
        },
        "name": "status-notification",
        "path": [
          "horseshoe-dark",
          "status",
          "notification"
        ]
      },
      "informational": {
        "default": {
          "value": "#259CFF",
          "type": "color",
          "description": "A standard informational message, providing additional context or guidance without implying any positive or negative sentiment.",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.blue-dark.800}",
            "type": "color",
            "description": "A standard informational message, providing additional context or guidance without implying any positive or negative sentiment.",
            "attributes": {
              "category": "color"
            }
          },
          "name": "status-informational",
          "path": [
            "horseshoe-dark",
            "status",
            "informational",
            "default"
          ]
        },
        "strong": {
          "value": "#63AFFF",
          "type": "color",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.blue-dark.900}",
            "type": "color",
            "attributes": {
              "category": "color"
            }
          },
          "name": "status-informational-strong",
          "path": [
            "horseshoe-dark",
            "status",
            "informational",
            "strong"
          ]
        },
        "subtle": {
          "value": "#1564C5",
          "type": "color",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.blue-dark.600}",
            "type": "color",
            "attributes": {
              "category": "color"
            }
          },
          "name": "status-informational-subtle",
          "path": [
            "horseshoe-dark",
            "status",
            "informational",
            "subtle"
          ]
        },
        "minimal": {
          "value": "#0E101A",
          "type": "color",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.blue-dark.200}",
            "type": "color",
            "attributes": {
              "category": "color"
            }
          },
          "name": "status-informational-minimal",
          "path": [
            "horseshoe-dark",
            "status",
            "informational",
            "minimal"
          ]
        }
      }
    },
    "rewards": {
      "background": {
        "default": {
          "value": "#D65E5E",
          "type": "color",
          "description": "When Caesars Rewards requires a call to action or reference.",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "{core.maroon-dark.700}",
            "type": "color",
            "description": "When Caesars Rewards requires a call to action or reference.",
            "attributes": {
              "category": "color"
            }
          },
          "name": "rewards-background",
          "path": [
            "horseshoe-dark",
            "rewards",
            "background",
            "default"
          ]
        }
      }
    },
    "spacing": {
      "2xs": {
        "value": "0.25rem",
        "type": "spacing",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "{core.spacing.1}",
          "type": "spacing",
          "attributes": {
            "category": "size"
          }
        },
        "name": "spacing-2xs",
        "path": [
          "horseshoe-dark",
          "spacing",
          "2xs"
        ]
      },
      "xs": {
        "value": "0.50rem",
        "type": "spacing",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "{core.spacing.2}",
          "type": "spacing",
          "attributes": {
            "category": "size"
          }
        },
        "name": "spacing-xs",
        "path": [
          "horseshoe-dark",
          "spacing",
          "xs"
        ]
      },
      "sm": {
        "value": "0.75rem",
        "type": "spacing",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "{core.spacing.3}",
          "type": "spacing",
          "attributes": {
            "category": "size"
          }
        },
        "name": "spacing-sm",
        "path": [
          "horseshoe-dark",
          "spacing",
          "sm"
        ]
      },
      "md": {
        "value": "1rem",
        "type": "spacing",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "{core.spacing.4}",
          "type": "spacing",
          "attributes": {
            "category": "size"
          }
        },
        "name": "spacing-md",
        "path": [
          "horseshoe-dark",
          "spacing",
          "md"
        ]
      },
      "lg": {
        "value": "1.5rem",
        "type": "spacing",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "{core.spacing.6}",
          "type": "spacing",
          "attributes": {
            "category": "size"
          }
        },
        "name": "spacing-lg",
        "path": [
          "horseshoe-dark",
          "spacing",
          "lg"
        ]
      },
      "xl": {
        "value": "2rem",
        "type": "spacing",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "{core.spacing.8}",
          "type": "spacing",
          "attributes": {
            "category": "size"
          }
        },
        "name": "spacing-xl",
        "path": [
          "horseshoe-dark",
          "spacing",
          "xl"
        ]
      },
      "2xl": {
        "value": "4rem",
        "type": "spacing",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "{core.spacing.16}",
          "type": "spacing",
          "attributes": {
            "category": "size"
          }
        },
        "name": "spacing-2xl",
        "path": [
          "horseshoe-dark",
          "spacing",
          "2xl"
        ]
      }
    },
    "box-shadow": {
      "2xs": {
        "value": {
          "x": "0",
          "y": "0.063rem",
          "blur": "0.063rem",
          "spread": "0",
          "color": "rgba(0,0,0,.10)",
          "type": "dropShadow"
        },
        "type": "boxShadow",
        "attributes": {
          "category": "boxShadow"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "{core.box-shadow.1}",
          "type": "boxShadow",
          "attributes": {
            "category": "boxShadow"
          }
        },
        "name": "box-shadow-2xs",
        "path": [
          "horseshoe-dark",
          "box-shadow",
          "2xs"
        ]
      },
      "xs": {
        "value": {
          "x": "0",
          "y": "0.125rem",
          "blur": "0.125rem",
          "spread": "0",
          "color": "rgba(0,0,0,0.1)",
          "type": "dropShadow"
        },
        "type": "boxShadow",
        "attributes": {
          "category": "boxShadow"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "{core.box-shadow.2}",
          "type": "boxShadow",
          "attributes": {
            "category": "boxShadow"
          }
        },
        "name": "box-shadow-xs",
        "path": [
          "horseshoe-dark",
          "box-shadow",
          "xs"
        ]
      },
      "sm": {
        "value": {
          "x": "0",
          "y": "0.25rem",
          "blur": "0.25rem",
          "spread": "0",
          "color": "rgba(0,0,0,0.1)",
          "type": "dropShadow"
        },
        "type": "boxShadow",
        "attributes": {
          "category": "boxShadow"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "{core.box-shadow.4}",
          "type": "boxShadow",
          "attributes": {
            "category": "boxShadow"
          }
        },
        "name": "box-shadow-sm",
        "path": [
          "horseshoe-dark",
          "box-shadow",
          "sm"
        ]
      },
      "md": {
        "value": {
          "x": "0",
          "y": "0.5rem",
          "blur": "0.5rem",
          "spread": "0",
          "color": "rgba(0,0,0,0.08)",
          "type": "dropShadow"
        },
        "type": "boxShadow",
        "attributes": {
          "category": "boxShadow"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "{core.box-shadow.8}",
          "type": "boxShadow",
          "attributes": {
            "category": "boxShadow"
          }
        },
        "name": "box-shadow-md",
        "path": [
          "horseshoe-dark",
          "box-shadow",
          "md"
        ]
      },
      "lg": {
        "value": {
          "x": "0",
          "y": "0.75rem",
          "blur": "0.75rem",
          "spread": "0",
          "color": "rgba(0,0,0,0.07)",
          "type": "dropShadow"
        },
        "type": "boxShadow",
        "attributes": {
          "category": "boxShadow"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "{core.box-shadow.12}",
          "type": "boxShadow",
          "attributes": {
            "category": "boxShadow"
          }
        },
        "name": "box-shadow-lg",
        "path": [
          "horseshoe-dark",
          "box-shadow",
          "lg"
        ]
      },
      "xl": {
        "value": {
          "x": "0",
          "y": "1.125rem",
          "blur": "1.125rem",
          "spread": "0",
          "color": "rgba(0,0,0,0.06)",
          "type": "dropShadow"
        },
        "type": "boxShadow",
        "attributes": {
          "category": "boxShadow"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "{core.box-shadow.18}",
          "type": "boxShadow",
          "attributes": {
            "category": "boxShadow"
          }
        },
        "name": "box-shadow-xl",
        "path": [
          "horseshoe-dark",
          "box-shadow",
          "xl"
        ]
      },
      "2xl": {
        "value": {
          "x": "0",
          "y": "1.5rem",
          "blur": "1.5rem",
          "spread": "0",
          "color": "rgba(0,0,0,0.05)",
          "type": "dropShadow"
        },
        "type": "boxShadow",
        "attributes": {
          "category": "boxShadow"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "{core.box-shadow.24}",
          "type": "boxShadow",
          "attributes": {
            "category": "boxShadow"
          }
        },
        "name": "box-shadow-2xl",
        "path": [
          "horseshoe-dark",
          "box-shadow",
          "2xl"
        ]
      }
    },
    "border-radius": {
      "sm": {
        "value": "0.25rem",
        "type": "borderRadius",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "{core.border-radius.1}",
          "type": "borderRadius",
          "attributes": {
            "category": "size"
          }
        },
        "name": "border-radius-sm",
        "path": [
          "horseshoe-dark",
          "border-radius",
          "sm"
        ]
      },
      "md": {
        "value": "0.5rem",
        "type": "borderRadius",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "{core.border-radius.2}",
          "type": "borderRadius",
          "attributes": {
            "category": "size"
          }
        },
        "name": "border-radius-md",
        "path": [
          "horseshoe-dark",
          "border-radius",
          "md"
        ]
      },
      "lg": {
        "value": "1rem",
        "type": "borderRadius",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "{core.border-radius.4}",
          "type": "borderRadius",
          "attributes": {
            "category": "size"
          }
        },
        "name": "border-radius-lg",
        "path": [
          "horseshoe-dark",
          "border-radius",
          "lg"
        ]
      }
    },
    "font-family": {
      "primary": {
        "value": "Roboto Flex, Roboto Condensed",
        "type": "fontFamilies",
        "attributes": {
          "category": "content"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "{core.font-family.roboto-flex}, {core.font-family.roboto-condensed}",
          "type": "fontFamilies",
          "attributes": {
            "category": "content"
          }
        },
        "name": "font-family-primary",
        "path": [
          "horseshoe-dark",
          "font-family",
          "primary"
        ]
      }
    }
  }
};