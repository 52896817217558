export const twClassMap = {
  Spacing: {
    "spacing.0": "w-0",
    "spacing.1": "w-1",
    "spacing.2": "w-2",
    "spacing.3": "w-3",
    "spacing.4": "w-4",
    "spacing.5": "w-5",
    "spacing.6": "w-6",
    "spacing.7": "w-7",
    "spacing.8": "w-8",
    "spacing.9": "w-9",
    "spacing.10": "w-10",
    "spacing.11": "w-11",
    "spacing.12": "w-12",
    "spacing.13": "w-13",
    "spacing.14": "w-14",
    "spacing.15": "w-15",
    "spacing.16": "w-16",
    "spacing.17": "w-17",
    "spacing.18": "w-18",
    "spacing.19": "w-19",
    "spacing.20": "w-20",
    "spacing.21": "w-21",
    "spacing.22": "w-22",
    "spacing.23": "w-23",
    "spacing.24": "w-24",
    "spacing.2xs": "w-2xs",
    "spacing.xs": "w-xs",
    "spacing.sm": "w-sm",
    "spacing.md": "w-md",
    "spacing.lg": "w-lg",
    "spacing.xl": "w-xl",
    "spacing.2xl": "w-2xl",
    "spacing.1px": "w-1px",
    "spacing.2px": "w-2px",
  },
  BorderWidth: {
    "border-width-0": "border-0",
    "border-width-1": "border-1",
    "border-width-2": "border-2",
    "border-width-3": "border-3",
    "border-width-4": "border-4",
    "border-width-5": "border-5",
    "border-width-6": "border-6",
  },
  BorderRadius: {
    "border-radius.0": "rounded-0",
    "border-radius.1px": "rounded-1px",
    "border-radius.2px": "rounded-2px",
    "border-radius.1": "rounded-1",
    "border-radius.2": "rounded-2",
    "border-radius.3": "rounded-3",
    "border-radius.4": "rounded-4",
    "border-radius.5": "rounded-5",
    "border-radius.6": "rounded-6",
    "border-radius.7": "rounded-7",
    "border-radius.8": "rounded-8",
    "border-radius.sm": "rounded-sm",
    "border-radius.md": "rounded-md",
    "border-radius.lg": "rounded-lg",
  },
  FontFamily: {
    "font-family.robot-condensed": "font-roboto-condensed",
    "font-family.oswald":
      "font-oswald",
    "font-family.sf-pro": "font-sf-pro",
    // "font-family.roboto": "font-roboto"
  },

  LineHeight: {
    "line-height.1": "leading-1",
    "line-height.2": "leading-2",
    "line-height.3": "leading-3",
    "line-height.4": "leading-4",
    "line-height.5": "leading-5",
    "line-height.6": "leading-6",
    "line-height.7": "leading-7",
    "line-height.8": "leading-8",
    "line-height.9": "leading-9",
    "line-height.10": "leading-10",
  },
  FontSize: {
    "font-size.1": "text-size-1",
    "font-size.2": "text-size-2",
    "font-size.3": "text-size-3",
    "font-size.4": "text-size-4",
    "font-size.5": "text-size-5",
    "font-size.6": "text-size-6",
    "font-size.7": "text-size-7",
    "font-size.8": "text-size-8",
    "font-size.9": "text-size-9",
    "font-size.10": "text-size-10",
    "font-size.11": "text-size-11",
    "font-size.12": "text-size-12",
    "font-size.13": "text-size-13",
    "font-size.14": "text-size-14",
    "font-size.15": "text-size-15",
  },
  FontWeight: {
    "font-weight.thin": "font-thin",
    "font-weight.light": "font-light",
    "font-weight.regular": "font-regular",
    "font-weight.medium": "font-medium",
    "font-weight.semibold": "font-semibold",
    "font-weight.bold": "font-bold",
    "font-weight.extraBold": "font-extraBold",
    "font-weight.black": "font-black",
  },
  LetterSpacing: {
    "letter-spacing.1": "tracking-1",
    "letter-spacing.2": "tracking-2",
    "letter-spacing.3": "tracking-3",
    "letter-spacing.4": "tracking-4",
    "letter-spacing.5": "tracking-5",
    "letter-spacing.6": "tracking-6",
    "letter-spacing.7": "tracking-7",
    "letter-spacing.8": "tracking-8",
    "letter-spacing.9": "tracking-9",
    "letter-spacing.10": "tracking-10",
    "letter-spacing.11": "tracking-11",
    "letter-spacing.12": "tracking-12",
  },
  Shadow: {
    "box-shadow.1": "shadow-1",
    "box-shadow.2": "shadow-2",
    "box-shadow.3": "shadow-3",
    "box-shadow.4": "shadow-4",
    "box-shadow.5": "shadow-5",
    "box-shadow.6": "shadow-6",
    "box-shadow.7": "shadow-7",
    "box-shadow.8": "shadow-8",
    "box-shadow.9": "shadow-9",
    "box-shadow.10": "shadow-10",
    "box-shadow.11": "shadow-11",
    "box-shadow.12": "shadow-12",
    "box-shadow.13": "shadow-13",
    "box-shadow.14": "shadow-14",
    "box-shadow.15": "shadow-15",
    "box-shadow.16": "shadow-16",
    "box-shadow.17": "shadow-17",
    "box-shadow.18": "shadow-18",
    "box-shadow.19": "shadow-19",
    "box-shadow.20": "shadow-20",
    "box-shadow.21": "shadow-21",
    "box-shadow.22": "shadow-22",
    "box-shadow.23": "shadow-23",
    "box-shadow.24": "shadow-24",
    "box-shadow.2xs": "shadow-2xs",
    "box-shadow.xs": "shadow-xs",
    "box-shadow.sm": "shadow-sm",
    "box-shadow.md": "shadow-md",
    "box-shadow.lg": "shadow-lg",
    "box-shadow.xl": "shadow-xl",
    "box-shadow.2xl": "shadow-2xl",
  },
};
