import React from "react";
import IconCard from "./IconCard";

const IconList = ({icons, hasSearchTerm, useTeamLogo, activeSport}) => {
    if (icons.length === 0 && hasSearchTerm) return (<>No results, please revise your search.</>)
    if (icons.length === 0 && !hasSearchTerm) return (<>Please enter a search term.</>)
    return (<div className="grid grid-cols-3 md:grid-cols-5 xl:grid-cols-9 grid-rows-auto gap-lg">
        {icons.map(({ obj: icon}) => {
            return <IconCard icon={icon} key={`${icon.name}`} useTeamLogo={useTeamLogo} activeSport={activeSport} />
        })}
    </div>)
}

export default IconList;