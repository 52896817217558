import { DetailMenOnBaseType, DetailProps, DetailTypes } from "../types";
import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import isTomorrow from "dayjs/plugin/isTomorrow";

dayjs.extend(isToday);
dayjs.extend(isTomorrow);

export const detailPresets: Record<
  DetailTypes,
  (tag: DetailProps) => DetailProps | DetailProps[]
> = {
  "men-on-base": ({ options }: any) => {
    return ({
      showDivider: false,
      leftIcon: { name: "icon_ui_baseball_diamond", size: "lg", options, className: "cui-absolute" }
    })
  },
  date: (tag) => {
    const _date = "date" in tag ? dayjs(tag.date) : "";
    const rightNow = dayjs(new Date());
    const diff = rightNow.diff(_date, "days");

    const isToday = _date && _date.isToday();
    const isTomorrow = _date && _date.isTomorrow();
    const isValidDate = _date && _date.isValid();

    let displayedDate = _date && _date.format("ddd").toUpperCase();
    if (isTomorrow) displayedDate = "Tomorrow";
    if (isToday) displayedDate = "Today";
    if (Math.abs(diff) >= 6)
      displayedDate = _date && _date.format("MMM D").toUpperCase();
    return isValidDate ? [
      { tag: isValidDate ? displayedDate : "", ...tag },
      { tag: isValidDate ? _date.format("h:mm A") : "", showDivider: true, ...tag },
    ] : [{ tag: "---", fgColor: "fg-subtle"}, { tag: "---", fgColor: "fg-subtle"}];
  },
  live: (tag) => ({
    leftIcon: { name: "ui_live_animated", size: 8 },
    tag: "Live",
    fgColor: "highlight-accent",
  }),
  livestream: ({ tag }) => ({
    leftIcon: { name: "ui_watch", size: "md", color: "highlight-accent" },
    tag: tag ? tag : "Watch",
  }),
  highlight: (tag) => ({
    fgColor: "highlight-accent",
  }),
  "highlight-bg": (tag) => ({
    bgColor: "ultramarine-300",
    fgColor: "highlight-accent",
  }),
  signpost: (tag) => ({
    textSize: "display-xs",
    fgColor: "fg-moderate",
  }),
  suspended: (tag) => ({
    tag: "Suspended",
    fgColor: "status-negative",
  }),
  "rain-delay": (tag) => ({
    tag: "Rain Delay",
    fgColor: "status-negative",
  }),
  "firebets": (tag) => ({
    tag: "", leftIcon: { name: "ui_fire-bets_color", size: "md" }
  }),
  "sgp": (tag) => ({ tag: "", leftIcon: { name: "ui_sgp", size: "md", color: "fg-primary" } })
};
