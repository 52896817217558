import React, { type Ref, type LegacyRef, forwardRef } from "react";
import { GridProps, GridCellProps } from "./types";
import { col, row, templateCols, gapClasses } from "./js/gridClasses";
import cx from "components/src/utils/cx";

export const Grid = ({
  columns = 12,
  flush,
  gap = "2xs",
  children,
  className = "",
  ...rest
}: GridProps) => {
  const styles = { "--cui-grid-cols": columns } as React.CSSProperties;
  return (
    <div
      className={cx(
        {
          "cui-mx-md": !flush,
        },
        `cui-grid ${templateCols[columns]} ${gapClasses[gap]} ${className}`
      )}
      style={styles}
      {...rest}
    >
      {children}
    </div>
  );
};

export const GridCell = forwardRef<HTMLDivElement, GridCellProps>(({
  colStart,
  colEnd,
  colSpan = 1,
  rowStart,
  rowEnd,
  rowSpan = 1,
  items,
  collapse = false,
  children,
  className = "",
  ...rest
}: GridCellProps, ref) => {
  const cxObj: Record<string, boolean> = {
    "cui-grid cui-place-items-center": items === "center",
    "cui-grid cui-place-items-end": items === "end",
    [col.span[colSpan]]: !!colSpan && !collapse,
    [row.span[rowSpan]]: !!rowSpan && !collapse,
  };
  colStart && (cxObj[col.start[colStart]] = !!colStart);
  colEnd && (cxObj[col.end[colEnd]] = !!colEnd);
  rowStart && (cxObj[row.start[rowStart]] = !!rowStart);
  rowEnd && (cxObj[row.end[rowEnd]] = !!rowEnd);
  return (
    <div className={cx(cxObj, `${className}`)?.trim()} {...rest} ref={ref}>
      {children}
    </div>
  );
});

Grid.Cell = GridCell;
