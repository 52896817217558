import React from "react";
import TableRow from "./components/TableRow";
import cx from "~/utils/cx";

const gridMap = {
  2: "grid-cols-[auto_1fr_1fr]",
  3: "grid-cols-[auto_2fr_1fr_1fr]",
  4: "grid-cols-5",
};

const ignoreKeys = [
  "filePath",
  "attributes",
  "type",
  "isSource",
  "original",
  "path",
  "sortorder", 
  "value"
];

export const Table = ({ data, theme, tokenGroup, type}) => {
  let headers;
  const dataIsArray = Array.isArray(data[0]);
  if (!dataIsArray) {
    headers = Object.keys(data[0]).filter(k => !ignoreKeys.includes(k) && data[0][k]);
    headers = ["", ...headers];
  } else {
    headers = ["", "Token Name", "Description"];
  }
  // const dataLength = Object.keys(data[0]).filter((k) => !ignoreKeys.includes(k)).length
  const dataLength = headers.length - 1
  return (
    <div className="min-w-[720px] mb-10">
      <div
        className={`grid grid-flow-row auto-rows-fr items-center text-size-6 ${gridMap[dataLength]}`}
      >
        {headers.map((h) => (
          <div
            className={cx(
              {
                "min-w-[200px]": h === "Name" || h === "Token Name",
                grow: h === "Description",
                "h-[75px]": !h,
              },
              "capitalize mr-11 py-4 text-size-8 w-full border-b-1 border-solid border-fg-bold"
            )}
            key={`headers-${h}`}
          >
            {h.split("_").join(" ")}
          </div>
        ))}

        {data.map((set, i) => {
          if (Array.isArray(set)) {
            const [name, data] = set;
            return (
              <TableRow
                key={`${name}-${i}`}
                name={data.name}
                data={data}
                theme={theme}
                tokenGroup={tokenGroup}
                type={type}
              />
            );
          }
          return (
            <TableRow
              key={`${set.name}-${i}`}
              name={set.name}
              data={set}
              theme={theme}
              tokenGroup={tokenGroup}
              type={type}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Table;
