import React, { ReactNode } from "react";
import { SVGProps } from "react-html-props";
import { LoadedIconProps } from "../types";

// fill, secondaryfill, width, height
export const BaseballDiamond = ({
  fill,
  secondaryfill,
  width,
  height,
  className,
  options = { first: false, second: false, third: false },
  ...rest
}: SVGProps & LoadedIconProps) => {
  let _width = typeof width === "string" ? +width : width;
  let _height: string | number = height === "100%" ? _width : 0;
  const iconSize = { width: _width, height: _height };
  const { first, second, third } = options;
  const onBase = "var(--highlight-accent)";
  const emptyBase = "var(--fg-subtle)";

  return (<svg
    width={width}
    height={width}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3514_4415)">
      {/* third */}
      <rect
        x="3.90283"
        y="6.92215"
        width="5.27908"
        height="5.27908"
        transform="rotate(45 3.90283 6.92215)"
        fill={third ? onBase : emptyBase}
      />
      {/* second */}
      <rect
        x="8.18994"
        y="2.43399"
        width="5.27908"
        height="5.27908"
        transform="rotate(45 8.18994 2.43399)"
        fill={second ? onBase : emptyBase}
      />
      {/* first */}
      <rect
        x="12.2764"
        y="6.98917"
        width="5.27908"
        height="5.27908"
        transform="rotate(45 12.2764 6.98917)"
        fill={first ? onBase : emptyBase}
      />
    </g>
    <defs>
      <clipPath id="clip0_3514_4415">
        <rect
          width="16"
          height="15.1111"
          fill="white"
          transform="translate(0 0.888885)"
        />
      </clipPath>
    </defs>
  </svg>
  );
};
