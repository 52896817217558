import React, { useEffect, useState, useCallback } from "react";
import Column from "./TableRowColumn";
import { nanoid } from "nanoid";
import {
  platformSelector,
  semanticThemeSelector,
} from "~/state/globalSlice";
import { useSelector } from "react-redux";
import copy from "copy-to-clipboard";
import { ClipBoardIcon } from "~/components/Icons/Icons";
import startCase from "lodash/startCase";
import { examples } from "../js/ExampleFormats";

const twKeyNameMap = {
  LineHeight: "line-height-",
  FontWeight: "font-weight-",
  FontFamily: "font-family-",
  LetterSpacing: "letter-spacing-",
  Shadow: "box-shadow-",
  BorderRadius: "border-radius-",
  Spacing: "spacing-",
  BorderWidth: "border-width-",
  FontSize: "font-",
};

const twSelector = {
  Spacing: "tw-p",
  Color: "tw-{util}",
  BorderWidth: "tw-border",
  BorderRadius: "tw-rounded",
  FontFamily: "tw-font",
  FontSize: "tw-text",
  LineHeight: "tw-leading",
  FontWeight: "tw-font",
  LetterSpacing: "tw-tracking",
  Shadow: "tw-shadow",
};

const semanticThemeMap = {
  "Caesars Light": "Theme.czr",
  "Caesars Dark": "Theme.czr",
  "William Hill Light": "Theme.wh",
  "William Hill Dark": "Theme.wh",
  "Caesars Palace Light": "Theme.caesarsPalace",
  "Caesars Palace Dark": "Theme.caesarsPalace"
};

function replaceKeyNames(category, val) {
  if (category in twKeyNameMap) return val.replace(twKeyNameMap[category], "");
  return val;
}

function platformValueTransformer(
  name,
  platform,
  tokenGroup,
  type,
  semanticTheme
) {
  let splitName = name.split(".");
  if (platform === "iOS" || platform === "Android") {
    const splitAll = splitName.map((s) => s.split("-")).flat();
    splitName = splitAll
      .map((s, i) => (i === 0 || /^\d/.test(s) ? s : startCase(s)))
      .join("");
  } else {
    splitName = splitName.join("-");
  }
  const [firstLetter, ...str] = tokenGroup;
  const group =
    type === "Foundational"
      ? firstLetter.toUpperCase() + str.join("")
      : semanticTheme
      ? semanticThemeMap[semanticTheme]
      : "CzrLight";

  return {
    "Web (CSS)": `--${splitName}`,
    Tailwind: `${twSelector[tokenGroup]}-${replaceKeyNames(
      tokenGroup,
      splitName
    )}`,
    iOS: `CUI.${group}.${splitName}`,
    Android: `CUI.${group}.${splitName}`,
  }[platform];
}

const TokenName = ({ name, tokenGroup = "Color", type }) => {
  const platform = useSelector(platformSelector);
  const semanticTheme = useSelector(semanticThemeSelector);
  const [colorState, setColorState] = useState("fill-gray-600");
  const [tName, setTName] = useState(
    platformValueTransformer(name, platform, tokenGroup, type, semanticTheme)
  );
  useEffect(() => {
    setTName(
      platformValueTransformer(name, platform, tokenGroup, type, semanticTheme)
    );
  }, [platform, semanticTheme, name, tokenGroup, type]);
  const handleClick = useCallback(() => {
    copy(tName);
    setColorState("fill-green-600");
    setTimeout(() => {
      setColorState("fill-gray-600");
    }, 1000);
  }, [tName]);
  return (
    <Column>
      <span
        role="presentation"
        className="peer bg-bg-layer-one text-fg-default text-size-6 rounded-[6px] p-3 cursor-pointer"
        onClick={handleClick}
      >
        {tName}
      </span>
      <ClipBoardIcon color={colorState} onClick={handleClick} />
    </Column>
  );
};

const unitTransformers = {
  remToInt(value) {
    const val = +value.replace("rem", "");
    return Math.floor(val * 16);
  },
  remToCGFloat(value) {
    const val = parseFloat(value.replace("rem", ""));
    return `CGFloat(${(val * 16).toFixed(2)})`;
  },
  remToDp(value) {
    const val = parseFloat(value);
    const baseFont = 16;
    if (isNaN(val)) return "0.dp";
    return (val * baseFont).toFixed(2) + ".dp";
  },
  remToSp(value) {
    const val = parseFloat(value);
    const baseFont = 16;
    if (isNaN(val)) return "0.sp";
    return (val * baseFont).toFixed(2) + ".sp";
  },
};

const PlatformValue = ({ value, group }) => {
  const platform = useSelector(platformSelector);
  const isRem = /rem$/.test(value);
  const isFontUnit = ["FontSize", "LetterSpacing"].includes(group);
  if (!isRem || !["iOS", "Android"].includes(platform)) return value;
  if (isRem && platform === "iOS") {
    return isFontUnit
      ? unitTransformers.remToCGFloat(value)
      : unitTransformers.remToInt(value);
  }
  if (isRem && platform === "Android") {
    return isFontUnit
      ? unitTransformers.remToSp(value)
      : unitTransformers.remToDp(value);
  }
};

const valueDiv = (val, theme, tokenGroup) => (
  <Column size="sm">
    <PlatformValue value={val} group={tokenGroup} />
  </Column>
);
const formattedDivs = {
  name: (name, theme, tokenGroup, type) => (
    <TokenName name={name} tokenGroup={tokenGroup} type={type} />
  ),
  figma_usage: (val) => <Column size="sm">{val}</Column>,
  current_value: valueDiv,
  value: valueDiv,
  description: (description) => <Column>{description}</Column>,
  example: (data, theme, tokenGroup) => {
    const type = tokenGroup || "Colors";
    return examples[type](data, tokenGroup, theme);
  },
};

const ignoreKeysList = ["type", "sortorder", "value"];

function formatData(data, theme, tokenGroup, type) {
  if (data.type === "color") {
    const formattedArr = [
      ["example", { value: data.value }],
      ["name", data.name],
      ["description", data.description],
    ];
    return formattedArr.map(([name, value]) =>
      formattedDivs[name](value, theme, tokenGroup, type)
    );
  } else {
    if (!("type" in data) && !data.description) data.description = " ";
    const dataToArr = Object.entries(data).filter(
      ([name, d]) => !ignoreKeysList.includes(name) && d
    );
    dataToArr.unshift(["example", data]);
    return dataToArr.map(([name, value]) =>
      formattedDivs[name](value, theme, tokenGroup, type)
    );
  }
}

export const TableRow = ({ data, theme, tokenGroup, type }) => {
  let _data = formatData(data, theme, tokenGroup, type);
  return (
    <>
      {_data.map((val, i) => (
        <React.Fragment key={nanoid(8)}>{val}</React.Fragment>
      ))}
    </>
  );
};
export default TableRow;
