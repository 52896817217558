import React from "react";
import { CardProps } from "../types";
import { CardSection } from './CardSection';

export const CardFooter = ({ children, isOpen, ...rest }: Partial<CardProps>) => (
  <CardSection x="cui-px-md" b={ isOpen ? "" : "cui-pb-sm" } {...rest}>
    {children}
  </CardSection>
);

CardFooter.displayName = "CardFooter";