import React from "react";
export const Chevron = ({ isOpen }) => (
  <div className="w-auto h-auto flex items-center justify-center">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`transition-all duration-200 ${isOpen ? "rotate-180" : ""}`}
    >
      <path
        d="M7.41 8.58997L12 13.17L16.59 8.58997L18 9.99997L12 16L6 9.99997L7.41 8.58997Z"
        fill="currentColor"
      />
    </svg>
  </div>
);

export default Chevron;
