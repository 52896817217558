import React from "react";
import { useSharedStorybook } from "~/hooks/useSharedStorybook";
import { ArgsTable } from "../ArgsTable/ArgsTable";

const Args = ({ component }) => {
    const shared = useSharedStorybook(component);
    const propsTables = {};
    for (let key in shared) {
        if (/props/ig.test(key)) propsTables[key] = shared[key];
    }
    return Object.entries(propsTables).map(([name, data]) => {
        const _name = name === "componentProps" || name === "argsTableProps" ? component?.code : convertName(name);
        return <React.Fragment key={`args-${name}`}>
            <h3>{_name}</h3>
            <ArgsTable data={data} className="mb-20" />
        </React.Fragment>
    })
}

function convertName(name) {
    let _name = name.replace("Props", "");
    const [firstLetter, ...rest] = _name;
    _name = `${firstLetter.toUpperCase()}${rest.join("")}`
    return _name;
}

export default Args;