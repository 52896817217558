import React from "react";

function Icon_ui_quickPick(props) {
  const fill = props.fill || "currentColor";
  const secondaryfill = props.secondaryfill || fill;
  const width = props.width || "100%";
  const height = props.height || "100%";

  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 24 23"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none">
        <g clipPath="url(#1695326058776-3484395_clip0_4248_48)">
          <path
            d="M4.31 11.62l1.11 1.61h-1.63l-1.03-1.59h-0.65c-0.74 0-1.24-0.56-1.24-1.38v-6.39c0-0.8 0.51-1.36 1.24-1.36h1.93c0.74 0 1.25 0.56 1.25 1.36v6.39c0 0.78-0.44 1.23-0.98 1.36z m-0.54-7.6h-1.38c-0.04 0-0.05 0.01-0.05 0.06v5.99c0 0.03 0.01 0.05 0.05 0.05h1.38c0.02 0 0.05-0.03 0.05-0.05v-5.99c0-0.04-0.02-0.05-0.05-0.06z"
            fill={fill}
          />
          <path
            d="M9.23 11.64h-1.93c-0.74 0-1.24-0.56-1.24-1.38v-7.73h1.48v7.54c0 0.03 0.01 0.05 0.04 0.05h1.38c0.02 0 0.05-0.03 0.05-0.05v-7.54h1.47v7.73c0 0.82-0.51 1.37-1.25 1.38z"
            fill={fill}
          />
          <path d="M11.2 11.61v-9.08h1.47v9.08h-1.47z" fill={fill} />
          <path
            d="M16.53 11.62h-1.93c-0.72 0-1.24-0.57-1.24-1.37v-6.38c0-0.79 0.52-1.36 1.24-1.36h1.93c0.73 0 1.25 0.57 1.25 1.36v1.82h-1.48v-1.61c0-0.04-0.02-0.05-0.04-0.06h-1.38c-0.04 0-0.05 0.01-0.05 0.06v5.99c0 0.03 0.01 0.05 0.05 0.05h1.38c0.02 0 0.05-0.03 0.04-0.05v-1.62h1.48v1.8c0 0.8-0.52 1.37-1.25 1.37z"
            fill={fill}
          />
          <path
            d="M21.99 11.61l-1.25-3.38-0.63 1.39v1.99h-1.48v-9.08h1.48v4.19l1.85-4.19h1.54l-1.8 3.98 1.94 5.1h-1.65z"
            fill={fill}
          />
          <path
            d="M11.14 17.4h-1.5v3.12h-1.3v-8.01h2.8c0.66 0 1.1 0.49 1.1 1.2v2.49c0 0.71-0.44 1.2-1.1 1.2z m-0.24-3.58h-1.26v2.26h1.26c0.02 0 0.04-0.01 0.04-0.05v-2.16c0-0.04-0.02-0.05-0.04-0.05z"
            fill={fill}
          />
          <path d="M12.71 20.52v-8.01h1.3v8.01h-1.3z" fill={fill} />
          <path
            d="M17.38 20.53h-1.71c-0.63 0-1.09-0.5-1.1-1.21v-5.64c0-0.7 0.46-1.2 1.1-1.2h1.71c0.65 0 1.1 0.5 1.1 1.2v1.66h-1.3v-1.47c0-0.03-0.02-0.04-0.04-0.05h-1.22c-0.03 0-0.04 0.01-0.04 0.05v5.29c0 0.02 0.01 0.05 0.04 0.04h1.22c0.02 0 0.04-0.02 0.04-0.04v-1.47h1.3v1.63c0 0.71-0.46 1.21-1.1 1.21z"
            fill={fill}
          />
          <path
            d="M22.18 20.52l-1.11-2.99-0.56 1.24v1.75h-1.3v-8.01h1.3v3.69l1.64-3.69h1.36l-1.59 3.51 1.72 4.5h-1.46z"
            fill={fill}
          />
          <path
            d="M3.82 20.51c-0.29 0-0.58-0.04-0.87-0.12a3.2 3.2 0 0 1-1.94-1.53 3.19 3.19 0 0 1-0.29-2.44 3.22 3.22 0 0 1 3.97-2.23 0.46 0.46 0 0 1 0.32 0.57 0.46 0.46 0 0 1-0.57 0.33 2.29 2.29 0 0 0-2.82 1.58 2.29 2.29 0 0 0 1.58 2.82 2.29 2.29 0 0 0 2.86-2.67 0.46 0.46 0 0 1 0.91-0.19 3.22 3.22 0 0 1-3.15 3.88z"
            fill={fill}
          />
          <path
            d="M3.94 18.81a0.46 0.46 0 0 1-0.37-0.18l-1.3-1.7a0.46 0.46 0 1 1 0.73-0.56l0.9 1.16 2.22-3.51a0.46 0.46 0 1 1 0.78 0.5l-2.57 4.07a0.47 0.47 0 0 1-0.37 0.22h-0.02z"
            fill={fill}
          />
        </g>
        <defs>
          <path
            d="M0 0h23.04v23.04h-23.04z"
            fill={fill}
            transform="translate(.625)"
          />
        </defs>
      </g>
    </svg>
  );
}

export default Icon_ui_quickPick;
