import React from "react";
import { CardPaddingType, CardProps } from "../types";
import cx from "components/src/utils/cx";


export const Pad = ({
  t, x, b, noPad, children, className, collapsible, isOpen, ...rest
}: Partial<CardProps> & CardPaddingType) => {
  const classes = cx({
    [`${x}`]: !noPad && !!x,
    [`${t}`]: !noPad && !!t,
    [`${b}`]: !noPad && !!b,
    [`${className || ""}`]: true,
  })?.trim();
  return (
    <div
      className={classes}
      {...rest}
    >
      {children}
    </div>
  );
}
