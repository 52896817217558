import React, { useState } from "react";
import ColorSwatchRow from "./ColorSwatchRow/ColorSwatchRow";
import Table from "../Table/Table";
import { useSelector, useDispatch } from "react-redux";
import { themeSelector, platformSelector, setSemanticTheme, semanticThemeSelector } from "~/state/globalSlice";
import { ButtonGroup } from "@caesars-digital/caesars-ui";
import PlatformSwitcher from "../PlatformSwitcher/PlatformSwitcher";
import { colorSetsCore as colorSets, semanticSets } from "./js/colorSets";
import useAllTokens from "~/hooks/useAllTokens";
import CzrLink  from "../CustomMDX/CzrLink/CzrLink";

const buttonGroupItems = {
  "color-system": [
    { itemId: 0, label: "Light Palette" },
    { itemId: 1, label: "Dark Palette" },
    { itemId: 2, label: "Brand Palette" },
    { itemId: 3, label: "Neutrals Palette" },
  ],
  "color-tokens": [
    { itemId: 0, label: "Caesars Light" },
    { itemId: 1, label: "Caesars Dark" },
    { itemId: 2, label: "William Hill Light" },
    { itemId: 3, label: "William Hill Dark" },
    { itemId: 4, label: "Caesars Palace Light" },
    { itemId: 5, label: "Caesars Palace Dark" },
    { itemId: 6, label: "Horseshoe Light" },
    { itemId: 7, label: "Horseshoe Dark" },
  ],
};

const colorSetMap = {
  "color-system": {
    0: "lightPalette",
    1: "darkPalette",
    2: "brandPalette",
    3: "neutralsPalette",
  },
  "color-tokens": {
    0: "czrLight",
    1: "czrDark",
    2: "whLight",
    3: "whDark",
    4: "czrPalaceLight",
    5: "czrPalaceDark",
    6: "horseshoeLight",
    7: "horseshoeDark"
  },
};

const createTokenDataSortOrderMap = (tokenData) =>
  tokenData.reduce((acc, token) => {
    acc[token.data.Name] = token.data.sortOrder;
    return acc;
  }, {});

let ranSort = false;

export const ColorSwatches = ({ page }) => {
  const theme = useSelector(themeSelector);
  const platform = useSelector(platformSelector);
  const semanticTheme = useSelector(semanticThemeSelector);
  const dispatch = useDispatch();
  const [activeColorSet, setActiveColorSet] = useState(colorSetMap[page][0]);
  const handleClick = (e, id) => {
    setActiveColorSet(colorSetMap[page][id]);
    const activeSemanticTheme = buttonGroupItems['color-tokens'].filter(item => item.itemId === id)[0].label
    dispatch(setSemanticTheme(activeSemanticTheme))
  };

  const tokenData = useAllTokens().allAirtableTokensColors.edges.map(
    (e) => e.node
  )
  const mappedTokenSortOrder = createTokenDataSortOrderMap(tokenData);
  // Add sortOrder from Airtable data to colorSets
  if (page === "color-system" && !ranSort) {
    ["lightPalette", "darkPalette"].map((palette) =>
      colorSets[palette].forEach((set) => {
        const [name] = set;
        if (name in mappedTokenSortOrder) {
          set.push({ sortOrder: mappedTokenSortOrder[name] });
        } else if (name !== "stops") {
          set.push({ sortOrder: 4 });
        }
      })
    );
    ranSort = true;
  }

  const [[, activeSemanticTheme]] = Object.entries(buttonGroupItems['color-tokens']).filter(([i, data])=> {
    return data.label === semanticTheme
  })

  return (
    <div className="flex flex-col">
      {/* Top Buttons  */}
      <PlatformSwitcher className="mb-5" />

      <ButtonGroup
        items={buttonGroupItems[page]}
        className="mb-10"
        handleClick={handleClick}
        initialItem={activeSemanticTheme.itemId}
      />

      {/* Tailwind Only Message  */}
      {platform === "Tailwind" && page === "color-tokens" && (
        <blockquote>
          <p>
            Tailwind color token names can be used with any utlity class that
            accepts color, to see a full list visit{" "}
            <CzrLink className="text-size-2" href="https://www.tailwindcss.com/docs" target="_blank">
              Tailwind Docs
            </CzrLink>
          </p>
        </blockquote>
      )}
      {platform === "Tailwind" &&
        ((page === "color-system" &&
          !["lightPalette", "darkPalette"].includes(activeColorSet)) ||
          page === "color-tokens") && (
          <p>
            Replace <code>{`tw-{util}`}</code> with a utility class e.g.{" "}
            <code>tw-bg | tw-text | tw-border | tw-ring</code>
          </p>
        )}
        
      {/* Color Tokens  */}
      {page === "color-tokens" && (
        <Table
          data={semanticSets[activeColorSet]
            .filter((t) => t.type === "color")
            .map((t) => ({
              value: t.value,
              name: t.name,
              description: t.description,
            }))}
          theme={theme}
        />
      )}

      {/* Color System  */}
      {page === "color-system" &&
        colorSets[activeColorSet]
          .sort(
            ([name, set, sort], [name2, set2, sort2]) =>
              sort?.sortOrder - sort2?.sortOrder
          )
          .map(([name, obj], i) => {
            if (["lightPalette", "darkPalette"].includes(activeColorSet)) {
              return (
                <ColorSwatchRow
                  key={`${name}-${i}`}
                  name={name}
                  colorSet={Object.entries(obj)}
                  theme={theme}
                />
              );
            } else {
              return (
                <Table
                  key={`${name}-${i}`}
                  name={name}
                  platform={platform}
                  data={Array.isArray(obj) ? obj : Object.entries(obj)}
                  theme={theme}
                  type="Foundational"
                />
              );
            }
          })}
    </div>
  );
};

export default ColorSwatches;
