import React from "react";
import ColorSwatch from "../ColorSwatch/ColorSwatch";


export const ColorSwatchRow = ({name, colorSet, theme}) => {
    const isColorStops = name === 'stops';
    return (<div className="grid grid-flow-col auto-cols-max gap-3 my-3 items-center">
        <div className="min-w-[100px]">{!isColorStops && name}</div>
        { isColorStops && colorSet.map(([name, stop]) => <div  key={`${name}`} className="w-[56px] flex justify-center">{stop.value}</div>)}
        { !isColorStops && colorSet.map(([colorStop, colorInfo]) => <ColorSwatch key={`${colorInfo.name}`} value={colorInfo.value} theme={theme} />)}
    </div>)
};

export default ColorSwatchRow;