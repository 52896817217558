import { useStaticQuery, graphql } from 'gatsby';

export const useVersion = () => {
  return useStaticQuery(graphql`
  query getVersion {
    allAirtableVersion {
      nodes {
        data {
          Version
          Release_Date
          Notes
        }
      }
    }
    allAirtableSwiftCuiVersion {
      nodes {
        data {
          Version
          Release_Date
          Notes
        }
      }
    }
  }
`);
};

export default useVersion;
