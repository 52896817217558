import React from "react";
import { Provider } from "react-redux";
import { RootQueryProvider } from "./src/context/RootQuery/RootQuery";
import store from "./src/state/store";

// CSS
import "./src/css/index.css";
import "@caesars-digital/caesars-ui/dist/css/caesars-ui.css";
require("prismjs/themes/prism-tomorrow.css");

export const wrapRootElement = ({ element }) => {
  return (
    <Provider store={store}>
      <RootQueryProvider>{element}</RootQueryProvider>
    </Provider>
  );
};
