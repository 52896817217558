import ColorSwatches from "~/components/ColorSwatches/ColorSwatches";
import TokenTable from "~/components/TokenTable/TokenTable";
import { default as typographyClasses } from 'tokens/build/js/semantic-typography';
import { Body, Heading, Display } from "@caesars-digital/caesars-ui";
import * as React from 'react';
export default {
  ColorSwatches,
  TokenTable,
  typographyClasses,
  Body,
  Heading,
  Display,
  React
};