import { configureStore, combineReducers } from '@reduxjs/toolkit';
// import { ThunkAction } from 'redux-thunk';

import global from './globalSlice';

const rootReducer = combineReducers({
  global
});

const store = configureStore({
  reducer: rootReducer
});

export default store;