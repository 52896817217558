import React from "react";

const CzrLogoDark = () => (
  <svg
    width="192"
    height="25"
    viewBox="0 0 192 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 12.2096C0 9.95655 0.519088 7.89677 1.55726 6.03026C2.59544 4.16375 4.03122 2.69484 5.86459 1.62353C7.70901 0.541177 9.75776 0 12.0108 0C13.7006 0 15.3076 0.325811 16.8317 0.977432C18.3669 1.62905 19.6536 2.52365 20.6917 3.66123L18.4221 5.89773C17.7042 5.11357 16.7654 4.47299 15.6058 3.976C14.4461 3.46795 13.2478 3.21393 12.0108 3.21393C9.58104 3.21393 7.5323 4.0754 5.86459 5.79833C4.20793 7.52126 3.3796 9.65835 3.3796 12.2096C3.3796 13.9657 3.77167 15.534 4.55583 16.9145C5.33998 18.2841 6.40577 19.3443 7.75319 20.0953C9.11166 20.8353 10.6303 21.2053 12.309 21.2053C13.7669 21.2053 15.0701 20.885 16.2187 20.2444C17.3784 19.5928 18.3503 18.7921 19.1345 17.8423L21.5366 20.0125C20.4985 21.3378 19.151 22.4036 17.4944 23.2099C15.8377 24.0161 14.1093 24.4192 12.309 24.4192C10.564 24.4192 8.92942 24.11 7.40529 23.4915C5.88116 22.873 4.57792 22.0281 3.49556 20.9568C2.41321 19.8855 1.55726 18.5933 0.927732 17.0802C0.309244 15.5671 0 13.9436 0 12.2096Z"
      fill="#173432"
    />
    <path
      d="M42.7751 24.0879L40.2735 17.9748H30.2673L27.7823 24.0879H24.2536L34.0942 0.331333H36.4798L46.3038 24.0879H42.7751ZM35.287 5.93086L31.6257 14.7609H38.9482L35.287 5.93086Z"
      fill="#173432"
    />
    <path
      d="M54.2558 9.67492H62.0587V12.8889H54.2558V20.8574H64.7756V24.0879H50.8596V0.331333H64.4443V3.56183H54.2558V9.67492Z"
      fill="#173432"
    />
    <path
      d="M68.47 20.4598L70.8224 18.157C72.5012 20.1892 74.4339 21.2053 76.6207 21.2053C77.7583 21.2053 78.7302 20.874 79.5365 20.2113C80.3538 19.5376 80.7624 18.6817 80.7624 17.6435C80.7624 16.9587 80.5857 16.3568 80.2323 15.8377C79.8788 15.3076 79.3874 14.8658 78.7578 14.5124C78.1394 14.1479 77.4988 13.8276 76.8361 13.5515C76.1845 13.2754 75.4445 12.9606 74.6162 12.6072C73.7878 12.2428 73.1086 11.8838 72.5785 11.5304C70.5684 10.194 69.5634 8.45451 69.5634 6.31189C69.5634 4.55583 70.2481 3.06483 71.6176 1.8389C72.9982 0.612966 74.7101 0 76.7533 0C79.0947 0 81.2208 0.789677 83.1314 2.36903L81.1269 4.85403C79.8347 3.76063 78.421 3.21393 76.8858 3.21393C75.8255 3.21393 74.9033 3.50108 74.1192 4.0754C73.3461 4.64971 72.9595 5.42834 72.9595 6.41129C72.9595 6.9856 73.1362 7.49917 73.4896 7.95199C73.8431 8.40481 74.329 8.80241 74.9475 9.14479C75.577 9.48717 76.2176 9.79641 76.8692 10.0725C77.5319 10.3486 78.2664 10.6689 79.0726 11.0334C79.8789 11.3979 80.5415 11.7568 81.0606 12.1102C83.1259 13.4687 84.1586 15.28 84.1586 17.5441C84.1586 19.5983 83.4572 21.2605 82.0546 22.5306C80.652 23.7897 78.8959 24.4192 76.7864 24.4192C75.1518 24.4192 73.6167 24.0824 72.1809 23.4087C70.7562 22.7239 69.5192 21.741 68.47 20.4598Z"
      fill="#173432"
    />
    <path
      d="M105.364 24.0879L102.862 17.9748H92.8561L90.3711 24.0879H86.8424L96.683 0.331333H99.0686L108.893 24.0879H105.364ZM97.8758 5.93086L94.2145 14.7609H101.537L97.8758 5.93086Z"
      fill="#173432"
    />
    <path
      d="M119.561 12.8889H116.845V24.0879H113.448V0.331333H121.765C123.687 0.331333 125.227 0.795199 126.387 1.72293C127.116 2.31933 127.685 3.04274 128.093 3.89316C128.513 4.73254 128.723 5.63818 128.723 6.61009C128.723 7.582 128.513 8.49317 128.093 9.34359C127.685 10.183 127.116 10.9009 126.387 11.4973C125.581 12.1599 124.653 12.5796 123.604 12.7563L132.119 24.0879H128.01L119.561 12.8889ZM116.845 9.67492H121.599C122.902 9.67492 123.852 9.37672 124.449 8.78032C125.034 8.19497 125.327 7.47156 125.327 6.61009C125.327 5.74863 125.034 5.02522 124.449 4.43986C123.863 3.85451 122.913 3.56183 121.599 3.56183H116.845V9.67492Z"
      fill="#173432"
    />
    <path
      d="M134.273 20.4598L136.625 18.157C138.304 20.1892 140.237 21.2053 142.423 21.2053C143.561 21.2053 144.533 20.874 145.339 20.2113C146.156 19.5376 146.565 18.6817 146.565 17.6435C146.565 16.9587 146.388 16.3568 146.035 15.8377C145.682 15.3076 145.19 14.8658 144.561 14.5124C143.942 14.1479 143.302 13.8276 142.639 13.5515C141.987 13.2754 141.247 12.9606 140.419 12.6072C139.591 12.2428 138.911 11.8838 138.381 11.5304C136.371 10.194 135.366 8.45451 135.366 6.31189C135.366 4.55583 136.051 3.06483 137.42 1.8389C138.801 0.612966 140.513 0 142.556 0C144.897 0 147.023 0.789677 148.934 2.36903L146.93 4.85403C145.637 3.76063 144.224 3.21393 142.689 3.21393C141.628 3.21393 140.706 3.50108 139.922 4.0754C139.149 4.64971 138.762 5.42834 138.762 6.41129C138.762 6.9856 138.939 7.49917 139.292 7.95199C139.646 8.40481 140.132 8.80241 140.75 9.14479C141.38 9.48717 142.02 9.79641 142.672 10.0725C143.335 10.3486 144.069 10.6689 144.875 11.0334C145.682 11.3979 146.344 11.7568 146.863 12.1102C148.929 13.4687 149.961 15.28 149.961 17.5441C149.961 19.5983 149.26 21.2605 147.857 22.5306C146.455 23.7897 144.699 24.4192 142.589 24.4192C140.955 24.4192 139.419 24.0824 137.984 23.4087C136.559 22.7239 135.322 21.741 134.273 20.4598Z"
      fill="#173432"
    />
    <path
      d="M171.673 24.4192C170.226 24.4192 168.862 24.1486 167.581 23.6075C166.311 23.0552 165.217 22.2877 164.301 21.3047C162.655 19.5155 161.832 17.163 161.832 14.2473V7.28932V0.331333H165.228V14.413C165.228 16.4452 165.725 18.0079 166.719 19.1013C167.978 20.504 169.63 21.2053 171.673 21.2053C173.716 21.2053 175.367 20.504 176.626 19.1013C177.62 18.0079 178.117 16.4452 178.117 14.413V0.331333H181.513V14.2473C181.513 17.152 180.685 19.5045 179.028 21.3047C178.112 22.2877 177.013 23.0552 175.732 23.6075C174.461 24.1486 173.109 24.4192 171.673 24.4192Z"
      fill="#A0875B"
    />
    <path d="M188.604 24.0879V0.331333H192V24.0879H188.604Z" fill="#A0875B" />
  </svg>
);

const CzrLogoLight = () => (
  <svg
    width="192"
    height="25"
    viewBox="0 0 192 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 12.2096C0 9.95655 0.519088 7.89677 1.55726 6.03026C2.59544 4.16375 4.03122 2.69484 5.86459 1.62353C7.70901 0.541177 9.75776 0 12.0108 0C13.7006 0 15.3076 0.325811 16.8317 0.977432C18.3669 1.62905 19.6536 2.52365 20.6917 3.66123L18.4221 5.89773C17.7042 5.11357 16.7654 4.47299 15.6058 3.976C14.4461 3.46795 13.2478 3.21393 12.0108 3.21393C9.58104 3.21393 7.5323 4.0754 5.86459 5.79833C4.20793 7.52126 3.3796 9.65835 3.3796 12.2096C3.3796 13.9657 3.77167 15.534 4.55583 16.9145C5.33998 18.2841 6.40577 19.3443 7.75319 20.0953C9.11166 20.8353 10.6303 21.2053 12.309 21.2053C13.7669 21.2053 15.0701 20.885 16.2187 20.2444C17.3784 19.5928 18.3503 18.7921 19.1345 17.8423L21.5366 20.0125C20.4985 21.3378 19.151 22.4036 17.4944 23.2099C15.8377 24.0161 14.1093 24.4192 12.309 24.4192C10.564 24.4192 8.92942 24.11 7.40529 23.4915C5.88116 22.873 4.57792 22.0281 3.49556 20.9568C2.41321 19.8855 1.55726 18.5933 0.927732 17.0802C0.309244 15.5671 0 13.9436 0 12.2096Z"
      fill="#E8E3DE"
    />
    <path
      d="M42.7751 24.0879L40.2735 17.9748H30.2673L27.7823 24.0879H24.2536L34.0942 0.331333H36.4798L46.3038 24.0879H42.7751ZM35.287 5.93086L31.6257 14.7609H38.9482L35.287 5.93086Z"
      fill="#E8E3DE"
    />
    <path
      d="M54.2558 9.67492H62.0587V12.8889H54.2558V20.8574H64.7756V24.0879H50.8596V0.331333H64.4443V3.56183H54.2558V9.67492Z"
      fill="#E8E3DE"
    />
    <path
      d="M68.47 20.4598L70.8224 18.157C72.5012 20.1892 74.4339 21.2053 76.6207 21.2053C77.7583 21.2053 78.7302 20.874 79.5365 20.2113C80.3538 19.5376 80.7624 18.6817 80.7624 17.6435C80.7624 16.9587 80.5857 16.3568 80.2323 15.8377C79.8788 15.3076 79.3874 14.8658 78.7578 14.5124C78.1394 14.1479 77.4988 13.8276 76.8361 13.5515C76.1845 13.2754 75.4445 12.9606 74.6162 12.6072C73.7878 12.2428 73.1086 11.8838 72.5785 11.5304C70.5684 10.194 69.5634 8.45451 69.5634 6.31189C69.5634 4.55583 70.2481 3.06483 71.6176 1.8389C72.9982 0.612966 74.7101 0 76.7533 0C79.0947 0 81.2208 0.789677 83.1314 2.36903L81.1269 4.85403C79.8347 3.76063 78.421 3.21393 76.8858 3.21393C75.8255 3.21393 74.9033 3.50108 74.1192 4.0754C73.3461 4.64971 72.9595 5.42834 72.9595 6.41129C72.9595 6.9856 73.1362 7.49917 73.4896 7.95199C73.8431 8.40481 74.329 8.80241 74.9475 9.14479C75.577 9.48717 76.2176 9.79641 76.8692 10.0725C77.5319 10.3486 78.2664 10.6689 79.0726 11.0334C79.8789 11.3979 80.5415 11.7568 81.0606 12.1102C83.1259 13.4687 84.1586 15.28 84.1586 17.5441C84.1586 19.5983 83.4572 21.2605 82.0546 22.5306C80.652 23.7897 78.8959 24.4192 76.7864 24.4192C75.1518 24.4192 73.6167 24.0824 72.1809 23.4087C70.7562 22.7239 69.5192 21.741 68.47 20.4598Z"
      fill="#E8E3DE"
    />
    <path
      d="M105.364 24.0879L102.862 17.9748H92.8561L90.3711 24.0879H86.8424L96.683 0.331333H99.0686L108.893 24.0879H105.364ZM97.8758 5.93086L94.2145 14.7609H101.537L97.8758 5.93086Z"
      fill="#E8E3DE"
    />
    <path
      d="M119.561 12.8889H116.845V24.0879H113.448V0.331333H121.765C123.687 0.331333 125.227 0.795199 126.387 1.72293C127.116 2.31933 127.685 3.04274 128.093 3.89316C128.513 4.73254 128.723 5.63818 128.723 6.61009C128.723 7.582 128.513 8.49317 128.093 9.34359C127.685 10.183 127.116 10.9009 126.387 11.4973C125.581 12.1599 124.653 12.5796 123.604 12.7563L132.119 24.0879H128.01L119.561 12.8889ZM116.845 9.67492H121.599C122.902 9.67492 123.852 9.37672 124.449 8.78032C125.034 8.19497 125.327 7.47156 125.327 6.61009C125.327 5.74863 125.034 5.02522 124.449 4.43986C123.863 3.85451 122.913 3.56183 121.599 3.56183H116.845V9.67492Z"
      fill="#E8E3DE"
    />
    <path
      d="M134.273 20.4598L136.625 18.157C138.304 20.1892 140.237 21.2053 142.423 21.2053C143.561 21.2053 144.533 20.874 145.339 20.2113C146.156 19.5376 146.565 18.6817 146.565 17.6435C146.565 16.9587 146.388 16.3568 146.035 15.8377C145.682 15.3076 145.19 14.8658 144.561 14.5124C143.942 14.1479 143.302 13.8276 142.639 13.5515C141.987 13.2754 141.247 12.9606 140.419 12.6072C139.591 12.2428 138.911 11.8838 138.381 11.5304C136.371 10.194 135.366 8.45451 135.366 6.31189C135.366 4.55583 136.051 3.06483 137.42 1.8389C138.801 0.612966 140.513 0 142.556 0C144.897 0 147.023 0.789677 148.934 2.36903L146.93 4.85403C145.637 3.76063 144.224 3.21393 142.689 3.21393C141.628 3.21393 140.706 3.50108 139.922 4.0754C139.149 4.64971 138.762 5.42834 138.762 6.41129C138.762 6.9856 138.939 7.49917 139.292 7.95199C139.646 8.40481 140.132 8.80241 140.75 9.14479C141.38 9.48717 142.02 9.79641 142.672 10.0725C143.335 10.3486 144.069 10.6689 144.875 11.0334C145.682 11.3979 146.344 11.7568 146.863 12.1102C148.929 13.4687 149.961 15.28 149.961 17.5441C149.961 19.5983 149.26 21.2605 147.857 22.5306C146.455 23.7897 144.699 24.4192 142.589 24.4192C140.955 24.4192 139.419 24.0824 137.984 23.4087C136.559 22.7239 135.322 21.741 134.273 20.4598Z"
      fill="#E8E3DE"
    />
    <path
      d="M171.673 24.4192C170.226 24.4192 168.862 24.1486 167.581 23.6075C166.311 23.0552 165.217 22.2877 164.301 21.3047C162.655 19.5155 161.832 17.163 161.832 14.2473V7.28932V0.331333H165.228V14.413C165.228 16.4452 165.725 18.0079 166.719 19.1013C167.978 20.504 169.63 21.2053 171.673 21.2053C173.716 21.2053 175.367 20.504 176.626 19.1013C177.62 18.0079 178.117 16.4452 178.117 14.413V0.331333H181.513V14.2473C181.513 17.152 180.685 19.5045 179.028 21.3047C178.112 22.2877 177.013 23.0552 175.732 23.6075C174.461 24.1486 173.109 24.4192 171.673 24.4192Z"
      fill="#A0875B"
    />
    <path d="M188.604 24.0879V0.331333H192V24.0879H188.604Z" fill="#A0875B" />
  </svg>
);

const CzrLogoWhite = () => (
  <svg
    width="192"
    height="25"
    viewBox="0 0 192 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 12.2098C0 9.95678 0.519088 7.897 1.55726 6.03049C2.59544 4.16398 4.03122 2.69507 5.86459 1.62376C7.70901 0.541406 9.75776 0.000228882 12.0108 0.000228882C13.7006 0.000228882 15.3076 0.32604 16.8317 0.977661C18.3669 1.62928 19.6536 2.52388 20.6917 3.66146L18.4221 5.89796C17.7042 5.1138 16.7654 4.47322 15.6058 3.97622C14.4461 3.46818 13.2478 3.21416 12.0108 3.21416C9.58104 3.21416 7.5323 4.07562 5.86459 5.79856C4.20793 7.52149 3.3796 9.65858 3.3796 12.2098C3.3796 13.9659 3.77167 15.5342 4.55583 16.9148C5.33998 18.2843 6.40577 19.3446 7.75319 20.0956C9.11166 20.8355 10.6303 21.2055 12.309 21.2055C13.7669 21.2055 15.0701 20.8852 16.2187 20.2447C17.3784 19.593 18.3503 18.7923 19.1345 17.8425L21.5366 20.0127C20.4985 21.3381 19.151 22.4039 17.4944 23.2101C15.8377 24.0163 14.1093 24.4195 12.309 24.4195C10.564 24.4195 8.92942 24.1102 7.40529 23.4917C5.88116 22.8732 4.57792 22.0283 3.49556 20.957C2.41321 19.8857 1.55726 18.5935 0.927732 17.0804C0.309244 15.5674 0 13.9438 0 12.2098Z"
      fill="white"
    />
    <path
      d="M42.7751 24.0881L40.2735 17.975H30.2673L27.7823 24.0881H24.2536L34.0942 0.331562H36.4798L46.3038 24.0881H42.7751ZM35.287 5.93109L31.6257 14.7611H38.9482L35.287 5.93109Z"
      fill="white"
    />
    <path
      d="M54.2558 9.67515H62.0587V12.8891H54.2558V20.8576H64.7756V24.0881H50.8596V0.331562H64.4443V3.56206H54.2558V9.67515Z"
      fill="white"
    />
    <path
      d="M68.47 20.46L70.8224 18.1573C72.5012 20.1894 74.4339 21.2055 76.6207 21.2055C77.7583 21.2055 78.7302 20.8742 79.5365 20.2115C80.3538 19.5378 80.7624 18.6819 80.7624 17.6437C80.7624 16.959 80.5857 16.357 80.2323 15.8379C79.8788 15.3078 79.3874 14.866 78.7578 14.5126C78.1394 14.1481 77.4988 13.8279 76.8361 13.5517C76.1845 13.2756 75.4445 12.9609 74.6162 12.6074C73.7878 12.243 73.1086 11.884 72.5785 11.5306C70.5684 10.1942 69.5634 8.45474 69.5634 6.31212C69.5634 4.55606 70.2481 3.06506 71.6176 1.83913C72.9982 0.613195 74.7101 0.000228882 76.7533 0.000228882C79.0947 0.000228882 81.2208 0.789906 83.1314 2.36926L81.1269 4.85426C79.8347 3.76086 78.421 3.21416 76.8858 3.21416C75.8255 3.21416 74.9033 3.50131 74.1192 4.07562C73.3461 4.64993 72.9595 5.42857 72.9595 6.41152C72.9595 6.98583 73.1362 7.4994 73.4896 7.95222C73.8431 8.40504 74.329 8.80264 74.9475 9.14502C75.577 9.4874 76.2176 9.79664 76.8692 10.0728C77.5319 10.3489 78.2664 10.6691 79.0726 11.0336C79.8789 11.3981 80.5415 11.757 81.0606 12.1104C83.1259 13.4689 84.1586 15.2802 84.1586 17.5443C84.1586 19.5986 83.4572 21.2608 82.0546 22.5309C80.652 23.7899 78.8959 24.4195 76.7864 24.4195C75.1518 24.4195 73.6167 24.0826 72.1809 23.4089C70.7562 22.7241 69.5192 21.7412 68.47 20.46Z"
      fill="white"
    />
    <path
      d="M105.364 24.0881L102.862 17.975H92.8561L90.3711 24.0881H86.8424L96.683 0.331562H99.0686L108.893 24.0881H105.364ZM97.8758 5.93109L94.2145 14.7611H101.537L97.8758 5.93109Z"
      fill="white"
    />
    <path
      d="M119.561 12.8891H116.845V24.0881H113.448V0.331562H121.765C123.687 0.331562 125.227 0.795428 126.387 1.72316C127.116 2.31956 127.685 3.04297 128.093 3.89339C128.513 4.73277 128.723 5.63841 128.723 6.61032C128.723 7.58223 128.513 8.4934 128.093 9.34382C127.685 10.1832 127.116 10.9011 126.387 11.4975C125.581 12.1601 124.653 12.5798 123.604 12.7565L132.119 24.0881H128.01L119.561 12.8891ZM116.845 9.67515H121.599C122.902 9.67515 123.852 9.37695 124.449 8.78055C125.034 8.1952 125.327 7.47179 125.327 6.61032C125.327 5.74886 125.034 5.02545 124.449 4.44009C123.863 3.85474 122.913 3.56206 121.599 3.56206H116.845V9.67515Z"
      fill="white"
    />
    <path
      d="M134.273 20.46L136.625 18.1573C138.304 20.1894 140.237 21.2055 142.423 21.2055C143.561 21.2055 144.533 20.8742 145.339 20.2115C146.156 19.5378 146.565 18.6819 146.565 17.6437C146.565 16.959 146.388 16.357 146.035 15.8379C145.682 15.3078 145.19 14.866 144.561 14.5126C143.942 14.1481 143.302 13.8279 142.639 13.5517C141.987 13.2756 141.247 12.9609 140.419 12.6074C139.591 12.243 138.911 11.884 138.381 11.5306C136.371 10.1942 135.366 8.45474 135.366 6.31212C135.366 4.55606 136.051 3.06506 137.42 1.83913C138.801 0.613195 140.513 0.000228882 142.556 0.000228882C144.897 0.000228882 147.023 0.789906 148.934 2.36926L146.93 4.85426C145.637 3.76086 144.224 3.21416 142.689 3.21416C141.628 3.21416 140.706 3.50131 139.922 4.07562C139.149 4.64993 138.762 5.42857 138.762 6.41152C138.762 6.98583 138.939 7.4994 139.292 7.95222C139.646 8.40504 140.132 8.80264 140.75 9.14502C141.38 9.4874 142.02 9.79664 142.672 10.0728C143.335 10.3489 144.069 10.6691 144.875 11.0336C145.682 11.3981 146.344 11.757 146.863 12.1104C148.929 13.4689 149.961 15.2802 149.961 17.5443C149.961 19.5986 149.26 21.2608 147.857 22.5309C146.455 23.7899 144.699 24.4195 142.589 24.4195C140.955 24.4195 139.419 24.0826 137.984 23.4089C136.559 22.7241 135.322 21.7412 134.273 20.46Z"
      fill="white"
    />
    <path
      d="M171.673 24.4195C170.226 24.4195 168.862 24.1489 167.581 23.6077C166.311 23.0555 165.217 22.2879 164.301 21.3049C162.655 19.5157 161.832 17.1633 161.832 14.2475V7.28955V0.331562H165.228V14.4132C165.228 16.4454 165.725 18.0082 166.719 19.1016C167.978 20.5042 169.63 21.2055 171.673 21.2055C173.716 21.2055 175.367 20.5042 176.626 19.1016C177.62 18.0082 178.117 16.4454 178.117 14.4132V0.331562H181.513V14.2475C181.513 17.1522 180.685 19.5047 179.028 21.3049C178.112 22.2879 177.013 23.0555 175.732 23.6077C174.461 24.1489 173.109 24.4195 171.673 24.4195Z"
      fill="white"
    />
    <path d="M188.604 24.0881V0.331562H192V24.0881H188.604Z" fill="white" />
  </svg>
);

const logos = {
  "czr-light": <CzrLogoDark />,
  "czr-dark": <CzrLogoLight />,
  "czr-white": <CzrLogoWhite />,
};

const Logo = ({theme}) => logos[theme];

export default Logo;
