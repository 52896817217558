/// <reference path="../../../../tokens/build/js/index.d.ts"/>

import React, { createElement } from "react";
import { TypographyComponentProps } from "./types";
import { default as typographyClasses } from 'tokens/build/js/semantic-typography';
import cx from "components/src/utils/cx";
import { tailwindSemanticColors as twColors } from "../../shared/styles/colorStyles";

const BaseTypography = (_tag: string, variant: string = 'body') => ({children, className = '', color = "fg-default", tag = _tag, size = 'md', ...rest}: TypographyComponentProps) => {
    let _size: string = !(size in typographyClasses[variant]) ? 'md' : size;
    let _color = color in twColors ? twColors[color] : color;
    return createElement(tag ? tag : 'span', {className: cx([typographyClasses[variant][_size], className, _color], "cui-box-border")?.trim(), ...rest}, children);
}
export const Body = BaseTypography('p', 'body');
export const Heading = BaseTypography('span', 'heading');
export const Display = BaseTypography('span', 'display');