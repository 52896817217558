import React from "react";
import Table from "../Table/Table";
// import coreTokens from "tokens/build/js/core";
import useAllTokens from "~/hooks/useAllTokens";
import PlatformSwitcher from "../PlatformSwitcher/PlatformSwitcher";

import {
  themeSelector,
  platformSelector,
} from "~/state/globalSlice";
import { useSelector } from "react-redux";
import startCase from "lodash/startCase";
import { nanoid } from "nanoid";
import SemanticThemeSwitcher from "../SemanticThemeSwitcher/SemanticThemeSwitcher";

function lowerCaseKeys(obj) {
  if (!(typeof obj === "object")) return obj;
  const newObj = {};
  for (let i in obj) {
    newObj[i.toLowerCase()] = obj[i];
  }
  return { ...newObj };
}

const TokenTable = ({
  tokenGroup,
  useMultiple = false,
  onlyShow,
  hideSwitcher = false,
  showTitle = false,
  type = "Foundational",
  ...rest
}) => {
  const allTokens = useAllTokens();
  const tokenMetaData = allTokens[`allAirtableTokens${tokenGroup}`].edges
    .map((e) => e.node.data)
    .filter((s) => s.Name && s.Type === type)
    .map((s) => lowerCaseKeys(s));
  const data = tokenMetaData.sort((a, b) => a.sortorder - b.sortorder);
  const theme = useSelector(themeSelector);
  const platform = useSelector(platformSelector);

  const showSemanticSwitcher =
    type === "Semantic" && ["iOS", "Android"].includes(platform);

  if (useMultiple) {
    const tableData = tokenMetaData.reduce((acc, token) => {
      const group = startCase(token.name.split(".")[0]).replace(" ", "");
      if (!(group in acc)) acc[group] = [];
      acc[group] = [...acc[group], token];
      return acc;
    }, {});
    return (
      <div {...rest}>
        {hideSwitcher ? null : showSemanticSwitcher ? (
          <div className="flex flex-col gap-5 mb-10">
            <PlatformSwitcher /> <SemanticThemeSwitcher />
          </div>
        ) : (
          <div className="flex flex-col gap-5 mb-10">
            <PlatformSwitcher />
          </div>
        )}
        {Object.entries(tableData)
          .filter(([group, data]) => {
            if (!onlyShow) return true;
            return group === onlyShow;
          })
          .map(([group, data]) => (
            <div className="flex flex-col" key={`table-${nanoid(8)}`}>
              {showTitle && <h2>{startCase(group)}</h2>}
              <Table data={data} theme={theme} tokenGroup={group} type={type} />
            </div>
          ))}
      </div>
    );
  } else {
    return (
      <div {...rest}>
        {hideSwitcher ? null : showSemanticSwitcher ? (
          <div className="flex flex-col gap-5 mb-10">
            <PlatformSwitcher /> <SemanticThemeSwitcher />
          </div>
        ) : (
          <div className="flex flex-col gap-5 mb-10">
            <PlatformSwitcher />
          </div>
        )}
        {showTitle && <h2>{startCase(tokenGroup)}</h2>}
        <Table data={data} theme={theme} tokenGroup={tokenGroup} type={type} />
      </div>
    );
  }
};

export default TokenTable;
