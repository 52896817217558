import React from "react";
import useComponents from "~/hooks/useAllComponents";
import { StatusPill } from "~/components/StatusPill/StatusPill";
import { Link } from "gatsby";
import { Icon } from "@caesars-digital/caesars-ui";
import kebabCase from "lodash/kebabCase";
import startCase from "lodash/startCase";
import masterChangelog from "changelog";

const { byComponent } = masterChangelog;

const changelogComponentNames = Object.keys(byComponent).map(name => name.toLowerCase());

const ListItem = ({ children }) => (
  <li className="text-size-3 list-none mr-3 pr-3 last:border-0 border-r-1 border-solid border-fg-minimal">
    {children}
  </li>
);
const PlatformLabel = ({ children }) => (
  <span className="mr-3">{children}</span>
);
export const ComponentStatusBar = ({ of = "" }) => {

  const asSlug = kebabCase(of.toLowerCase());
  const asComponentName = startCase(of).replace(" ", "").toLowerCase();
  const component =
    useComponents()
      .allAirtableComponents.edges.map((e) => e.node.data)
      .filter(
        (d) =>
          d.Type === "Component" && d.Slug.toLowerCase() === asSlug
      )[0] || null;
  if (!component) return null;
  return (
    <div className="my-8 flex justify-between items-center">
      <ul className="list-none flex items-start text-size-3 font-bold">
        <ListItem>
          <PlatformLabel>Figma:</PlatformLabel>
          <StatusPill status={component.Figma_Library} />
        </ListItem>
        <ListItem>
          <PlatformLabel>Web:</PlatformLabel>
          <StatusPill status={component.Web} />
        </ListItem>
        <ListItem>
          <PlatformLabel>iOS:</PlatformLabel>
          <StatusPill status={component.iOS} />
        </ListItem>
      </ul>
      { changelogComponentNames.includes(asComponentName) ? <Link to="changelog" className="flex items-center">
        <Icon name="icon_ui_bullet-list" />
        <span className="pl-2 body-lg text-fg-moderate">Changelog</span>
      </Link> : null}
    </div>
  );
};

export default ComponentStatusBar;
